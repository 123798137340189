import React from "react";
import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import {
  Card,
  Button,
  Typography,
  CardHeader,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../routes/paths";
import { useAuthContext } from "src/auth/useAuthContext";
import { useNavigate } from "react-router";
import { fetchAssignHistory } from "src/service";
import Scrollbar from "src/components/scrollbar/Scrollbar";
import { useTable, TablePaginationCustom } from "../../components/table";

const TABLE_HEAD = [
  // { id: 'Id', label: 'Id', align: 'left' },
  {
    id: "Assign By/Assign To/ Date & Time",
    label: "Assign By/Assign To/ Date & Time",
  },
  { id: "Start Id/End Id", label: "Start Id/End Id" },
  { id: "Report", label: "Report" },
];

// Sample data to populate the table
const tableData = [
  {
    assignInfo: "Kajal / Ritu\n12/07/24 10:35 AM",
    startEndId: "4362/7384",
    report: {
      totalAssignLink: 50,
      onlyAdmin: 5,
      joinBasic: 7,
      jainSpecial: 4,
      jainMatrimony: 6,
    },
  },
  {
    assignInfo: "Deeksha / Priya\n25/08/24 12:50 PM",
    startEndId: "3274/6482",
    report: {
      totalAssignLink: 60,
      onlyAdmin: 10,
      joinBasic: 8,
      jainSpecial: 5,
      jainMatrimony: 6,
    },
  },
];
const AssignHistory = () => {
  const {
    dense,
    page,
    rowsPerPage,
    onChangeDense,
    onChangePage,
    onChangePageButton,
    onChangeRowsPerPage,
  } = useTable();
  const contxt = useAuthContext();
  const navigate = useNavigate();
  const [assignHistorydata, setAssignHistoryData] = useState([]); // To store fetched data
  const [date] = useState(new Date().toISOString().split("T")[0]); // Default: today's date
  const [staffId] = useState(""); // Staff ID for filtering
  // Fetch data when the component loads or filters change
  const getAssignHistory = async () => {
    try {
      const result = await fetchAssignHistory({
        date,
        staffId,
        page,
        limit: 10,
      });
      setAssignHistoryData(result?.data?.data); // Store data
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  // Fetch data on component mount and when filters change
  useEffect(() => {
    getAssignHistory();
  }, [date, staffId, page]);

  // Handle user filter actions

  return (
    <>
      <Helmet>
        {" "}
        <title>Asign History : Master Admin</title>{" "}
      </Helmet>
      <Card>
        {contxt?.user?.sections?.offlineUsers?.create ? (
          <CardHeader
            action={
              <>
                <Button
                  className="mx-1"
                  variant="contained"
                  onClick={() =>
                    navigate(PATH_DASHBOARD?.general?.assignhistory)
                  }
                >
                  Assign History
                </Button>

                <Button
                  className="mx-1"
                  variant="contained"
                  onClick={() => navigate(PATH_DASHBOARD?.general?.grouplinks)}
                >
                  Back
                </Button>
              </>
            }
            title={<Typography variant="h4">Group Link's</Typography>}
          />
        ) : (
          <CardHeader
            title={<Typography variant="h4">Group Link's</Typography>}
          />
        )}
      </Card>
      <TableContainer component={Paper}>
        <Scrollbar>
          <Table>
            {/* Table Head */}
            <TableHead>
              <TableRow>
                {TABLE_HEAD.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.id === "Report" ? "center" : "left"}
                    sx={{
                      backgroundColor: "red",
                      color: "white",
                      fontWeight: "bold",
                    }}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* Table Body */}
            <TableBody>
              {assignHistorydata.map((row, index) => (
                <TableRow key={index}>
                  {/* Assign Info */}
                  <TableCell>
                    <Typography
                      variant="body2"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {row?.assignByAdmin?.name?.english ||
                        row?.assignByAdmin?.name ||
                        "N/A"}{" "}
                      /
                      {row?.assignToStaff?.name?.english ||
                        row?.assignToStaff?.name ||
                        "N/A"}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "red" }}>
                      {row?.createdAt}
                    </Typography>
                  </TableCell>

                  {/* Start/End ID */}
                  <TableCell>
                    <Typography variant="body2">
                      {row?.firstTaskId || "N/A"} /{" "}
                      <Typography component="span" sx={{ color: "red" }}>
                        {row?.lastTaskId || "N/A"}
                      </Typography>
                    </Typography>
                  </TableCell>

                  {/* Report */}
                  <TableCell>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                      }}
                    >
                      <Box>
                        <Typography variant="body2">
                          Total Assign Link -{" "}
                          <span style={{ color: "blue" }}>
                            {row?.jainMatrimonyCount || 0}
                          </span>
                        </Typography>

                        <Typography variant="body2">
                          Join Link -{" "}
                          <span style={{ color: "blue" }}>
                            {row?.groupJoinedCount || 0}
                          </span>
                        </Typography>
                        <Typography variant="body2">
                          Added Link -
                          <span style={{ color: "blue" }}>
                            {row?.onlyAdminCount || 0}
                          </span>
                        </Typography>
                        <Typography variant="body2">
                          Join Not Posible -
                          <span style={{ color: "blue" }}>
                            {row?.groupJoinNotPossibleCount || 0}
                          </span>
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2">
                          Only Admin -
                          <span style={{ color: "blue" }}>
                            {" "}
                            {row?.onlyAdminCount || 0}
                          </span>
                        </Typography>
                        <Typography variant="body2">
                          Join Basic -{" "}
                          <span style={{ color: "blue" }}>
                            {row?.jainBasicCount || 0}
                          </span>
                        </Typography>
                        <Typography variant="body2">
                          Jain Special -{" "}
                          <span style={{ color: "blue" }}>
                            {row?.jainSpecialCount || 0}
                          </span>
                        </Typography>
                        <Typography variant="body2">
                          Jain Matrimony - {row?.jainMatrimonyCount || 0}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
      <TablePaginationCustom
        onChangePageButton={onChangePageButton}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        //
        dense={dense}
        onChangeDense={onChangeDense}
      />
    </>
  );
};

export default AssignHistory;
