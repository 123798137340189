import { useEffect, useState } from "react";
import {
  Card,
  Table,
  CardContent,
  Stack,
  Typography,
  TableBody,
  TableContainer,
  Box,
  TableRow,
  TableCell,
} from "@mui/material";
import { _stafflist } from "../../_mock/arrays";
import Scrollbar from "../../components/scrollbar";
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from "../../components/table";
import { getOfflineExcelHistoryApi } from "src/service";
import LoadingScreenSmall from "src/components/loading-screen/LoadingScreenSmall";
import { useLocation } from "react-router-dom";
const TABLE_HEAD = [
  { id: "Section", label: "Section", align: "left" },
  { id: "Type", label: " Data Type", align: "left" },
  { id: "Toatal", label: " Total", align: "left" },

  { id: "Start Id/End Id", label: "Start Id/End _id", align: "left" },

  // { label: "Staf ID", align: "left" },
  { label: "Staf Name", align: "left" },
  { label: "Date & Time", align: "left" },
];

export default function DownlaodDataHistory() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    onChangePageButton,
  } = useTable();
  const [isNotFound, setIsNotFound] = useState(false);
  const [excelOfflineHistoryList, setExcelOfflineHistoryList] = useState([]);
  console.log("excelHistoryList ::::", excelOfflineHistoryList);
  const location = useLocation();
  // useEffect(() => {
  //   getOfflineExcelHistory();
  // }, [page, rowsPerPage]);

  useEffect(() => {
    const section = location.state?.section || "offline";
    getExcelHistory(section);
  }, [location, page, rowsPerPage]);

  const getExcelHistory = async (section) => {
    try {
      setIsNotFound(true);
      let res = await getOfflineExcelHistoryApi(page, rowsPerPage, section, {});
      setExcelOfflineHistoryList(res?.data?.data.user);
      setIsNotFound(res?.data?.data.total === 0);
    } catch (error) {
      console.error("Error fetching Excel history:", error);
      setIsNotFound(false);
    }
  };

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Excel History
      </Typography>
      <Card>
        <CardContent sx={{ py: 2, px: 2 }}>
          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  sx={{
                    "& .MuiTableCell-head": {
                      backgroundColor: "red",
                      color: "white",
                    },
                  }}
                />
                <TableBody>
                  {excelOfflineHistoryList.map((row) => (
                    <TableRow hover key={row.id}>
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            noWrap
                          >
                            {row?.type}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            noWrap
                          >
                            {row?.sub_type}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            noWrap
                          >
                            {row?.total_download}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            noWrap
                          >
                            {row?.start_id}-{row?.end_id}
                          </Typography>
                        </Stack>
                      </TableCell>
                      {/* <TableCell align="left">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            noWrap
                          >
                            {row?.staff_id}
                          </Typography>
                        </Stack>
                      </TableCell> */}
                      <TableCell align="left">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            noWrap
                          >
                            {row?.ExcelHistoryDownloadStaffName?.name
                              ?.english || row?.staff_name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Typography
                            variant="body2"
                            color="textPrimary"
                            noWrap
                          >
                            {new Date(row?.created_at).toLocaleTimeString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* Display "No Data" message if applicable */}
                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
            {/* Show loading indicator if excelHistoryList is empty */}
            {!excelOfflineHistoryList.length && !isNotFound && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <LoadingScreenSmall />
              </Box>
            )}
          </TableContainer>
        </CardContent>

        <TablePaginationCustom
          // count={total}
          page={page}
          onChangePageButton={onChangePageButton}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          //
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
}
