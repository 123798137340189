// @mui
import { Tooltip, Stack, Typography, Box } from "@mui/material";
// hooks
import { useAuthContext } from "../../auth/useAuthContext";
// routes
// layouts
import LoginLayout from "../../layouts/login";
//
import AuthLoginForm from "./AuthLoginForm";

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
        <Typography variant="h4">Sign in to JSM</Typography>

        {/* <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <Link to={PATH_AUTH.register} component={RouterLink} variant="subtitle2">
            Create an account
          </Link>
        </Stack> */}

        <Tooltip title={method} placement="left" style={{ margin: 0 }}>
          <Box
            component="img"
            alt={method}
            src={`/assets/icons/auth/ic_${method}.png`}
            sx={{ width: 32, height: 32, position: "absolute", right: 0 }}
          />
        </Tooltip>
      </Stack>
      <AuthLoginForm />
    </LoginLayout>
  );
}
