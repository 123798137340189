import { Navigate, useRoutes } from "react-router-dom";
import React from "react";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config";
import LoadingScreenSmall from "src/components/loading-screen/LoadingScreenSmall";
//
import {
  // Auth
  LoginPage,
  RegisterPage,
  NewPasswordPage,
  ResetPasswordPage,
  // Dashboard: General
  GeneralAppPage,
  Page404,
} from "./elements";
import NewGlobalSearch from "src/pages/General/NewGlobalSearch";
import DownlaodDataHistory from "src/pages/General/DownlaodDataHistory";
import OfficeAccessoriesHistory from "src/pages/General/OfficeAccessoriesHistory";
import AnalyticsHistory from "src/pages/General/AnalyticsHistory";
import WhatsAppAnalyticsHistory from "src/pages/General/WhatsAppAnalyticsHistory";
import ViewScannerDetails from "src/pages/General/ViewScannerDetails";
import AddDeviceHistory from "src/pages/General/AddDeviceHistory";
import AssignHistory from "src/pages/General/AssignHistory";
import ViewWorkFromHomeStaffTask from "src/pages/General/ViewWorkFromHomeStaffTask";
import ScannerBiodataCopyHistory from "src/pages/General/ScannerBiodataCopyHistory";
import HinduScannerBiodataCopyHistory from "src/pages/General/HinduScannerBiodataCopyHistory";
import ViewStaffAssignTaskReport from "src/pages/General/ViewStaffAssignTaskReport";
import ScannerUploadReport from "src/pages/General/ScannerUploadReport";

const NewOnlineUser = React.lazy(() =>
  import("src/pages/General/NewOnlineUser")
);
const OfflineUsers = React.lazy(() => import("src/pages/General/OfflineUsers"));
const BannerPage = React.lazy(() => import("src/pages/General/BannerPage"));
const Mobiles = React.lazy(() => import("src/pages/General/Mobiles"));
const Mystaff = React.lazy(() => import("src/pages/General/Mystaff"));
const ViewOnlineUser = React.lazy(() =>
  import("src/pages/General/ViewOnlineUser")
);
const MyPackages = React.lazy(() => import("src/pages/General/MyPackages"));
const BioDataUsers = React.lazy(() => import("src/pages/General/BioDataUsers"));
const ScannerBioDataUsers = React.lazy(() =>
  import("src/pages/General/ScannerBiodataUsers")
);
const HinduScannerBioDataUsers = React.lazy(() =>
  import("src/pages/General/HinduScannerBiodataUser")
);
const BioDataEntryForms = React.lazy(() =>
  import("src/pages/General/BioDataEntryForms")
);
const HinduCasteEntries = React.lazy(() =>
  import("src/pages/General/HinduCasteEntries")
);
const HinduBioData = React.lazy(() => import("src/pages/General/HinduBioData"));
const ImagePreview = React.lazy(() => import("src/pages/General/ImagePreview"));
const HinduBioDataEntryForms = React.lazy(() =>
  import("src/pages/General/HinduBioDataEntryForms")
);
const OfflineUsersAdd = React.lazy(() =>
  import("src/pages/General/OfflineUsersAdd")
);
const Padadhikari = React.lazy(() => import("src/pages/General/Padadhikari"));
const Countries = React.lazy(() => import("src/pages/Forms/Countries"));
const States = React.lazy(() => import("src/pages/Forms/States"));
const Cities = React.lazy(() => import("src/pages/Forms/Cities"));
const Division = React.lazy(() => import("src/pages/Forms/Division"));
const Tehsils = React.lazy(() => import("src/pages/Forms/Tehsils"));
const Educations = React.lazy(() => import("src/pages/Forms/Educations"));
const Subeducations = React.lazy(() => import("src/pages/Forms/Subeducations"));
const Subsects = React.lazy(() => import("src/pages/Forms/Subsect"));
const Sects = React.lazy(() => import("src/pages/Forms/Sects"));
const Gotras = React.lazy(() => import("src/pages/Forms/Gotras"));
const Hobbies = React.lazy(() => import("src/pages/Forms/Hobbies"));
const MotherTongues = React.lazy(() => import("src/pages/Forms/Mothertongues"));
const Occupations = React.lazy(() => import("src/pages/Forms/Occupations"));
const Suboccupations = React.lazy(() =>
  import("src/pages/Forms/Suboccupations")
);
const Specialcollegespreferences = React.lazy(() =>
  import("src/pages/Forms/Specialcollegespreferences")
);
const Raashi = React.lazy(() => import("src/pages/Forms/Raashi"));
const Starnakshatra = React.lazy(() => import("src/pages/Forms/Starnakshatra"));
const Forms = React.lazy(() => import("src/pages/General/Forms"));
const RoleManagement = React.lazy(() =>
  import("src/pages/General/RoleManagement")
);
const StaffReport = React.lazy(() => import("src/pages/General/StaffReport"));

const WhatsappResponse = React.lazy(() =>
  import("src/pages/General/WhatsappResponse")
);
const HinduWhatsappResponse = React.lazy(() =>
  import("src/pages/General/HinduWhatsappResponse")
);
const WhatsappResponseAdd = React.lazy(() =>
  import("src/pages/General/WhatsappResponseAdd")
);
const HinduWhatsappResponseAdd = React.lazy(() =>
  import("src/pages/General/HinduWhatsappResponseAdd")
);
const Message = React.lazy(() => import("src/pages/General/Message"));
const Notes = React.lazy(() => import("src/pages/General/Notes"));
const FieldWork = React.lazy(() => import("src/pages/General/FieldWork"));
const FieldWorkAdd = React.lazy(() => import("src/pages/General/FieldWorkAdd"));

const Hinduforms = React.lazy(() => import("src/pages/General/HinduForms"));
const HinduCountries = React.lazy(() =>
  import("src/pages/Hinduforms/Countries")
);
const HinduStates = React.lazy(() => import("src/pages/Hinduforms/States"));
const HinduDivisions = React.lazy(() =>
  import("src/pages/Hinduforms/Division")
);
const HinduCities = React.lazy(() => import("src/pages/Hinduforms/Cities"));
const HinduTehsils = React.lazy(() => import("src/pages/Hinduforms/Tehsils"));
const HinduEducations = React.lazy(() =>
  import("src/pages/Hinduforms/Educations")
);
const HinduSubeducations = React.lazy(() =>
  import("src/pages/Hinduforms/Subeducations")
);
const HinduSubsects = React.lazy(() => import("src/pages/Hinduforms/Subsects"));
const HinduSects = React.lazy(() => import("src/pages/Hinduforms/Sects"));
const HinduGotras = React.lazy(() => import("src/pages/Hinduforms/Gotras"));
const HinduOccupations = React.lazy(() =>
  import("src/pages/Hinduforms/Occupations")
);
const HinduSuboccupations = React.lazy(() =>
  import("src/pages/Hinduforms/Suboccupations")
);
const GlobalSearch = React.lazy(() => import("src/pages/General/GlobalSearch"));
const ExcelHistory = React.lazy(() => import("src/pages/General/ExcelHistory"));
const HinduBioDataUpdate = React.lazy(() =>
  import("src/pages/General/HinduBioDataUpdate")
);
const BioDataUpdate = React.lazy(() =>
  import("src/pages/General/BioDataUpdate")
);
const PushNotification = React.lazy(() =>
  import("src/pages/General/PushNotification")
);
const PushNotificationAdd = React.lazy(() =>
  import("src/pages/General/PushNotificationAdd")
);
const HinduIncoming = React.lazy(() =>
  import("src/pages/General/HinduIncoming")
);
const HinduIncomingAdd = React.lazy(() =>
  import("src/pages/General/hinduIncomingAdd")
);
const TestUserHistory = React.lazy(() =>
  import("src/pages/General/TestUserHistory")
);
const UserRequest = React.lazy(() => import("src/pages/General/UserRequest"));
const ContactUs = React.lazy(() => import("src/pages/General/ContactUs"));
const PushNotificationHistory = React.lazy(() =>
  import("src/pages/General/PushNotificationHistory")
);
const ResponseData = React.lazy(() => import("src/pages/General/ResponseData"));
const WhatsappGroupServices = React.lazy(() =>
  import("src/pages/General/WhatsappGroupServices")
);
const PaidPromotion = React.lazy(() =>
  import("src/pages/General/PaidPromotion")
);
const SocialMedia = React.lazy(() => import("src/pages/General/SocialMedia"));
const ExcelImport = React.lazy(() =>
  import("src/sections/@dashboard/user/list/ExcelImport")
);
const PendingTask = React.lazy(() => import("src/pages/General/PendingTask"));
const IncomingUser = React.lazy(() => import("src/pages/General/IncomingUser"));
const IncomingUserAdd = React.lazy(() =>
  import("src/pages/General/IncomingUserAdd")
);
const MessageSection = React.lazy(() =>
  import("src/pages/General/MessageSection")
);
const GroupLinks = React.lazy(() => import("src/pages/General/GroupLinks"));
const HinduOnlineUser = React.lazy(() =>
  import("src/pages/General/HinduOnlineUser")
);
const Settings = React.lazy(() => import("src/pages/General/Settings"));
const MessageView = React.lazy(() => import("src/pages/General/MessageView"));
const FieldApprovel = React.lazy(() =>
  import("src/pages/General/FieldApprovel")
);
const HinduResponseData = React.lazy(() =>
  import("src/pages/General/HinduResponseData")
);
const HinduViewOnlineUser = React.lazy(() =>
  import("src/pages/General/HinduViewOnlineUser")
);
const HinduImagePreview = React.lazy(() =>
  import("src/pages/General/HinduImagePreview")
);
const HinduPushNotificationHistory = React.lazy(() =>
  import("src/pages/General/HinduPushNotificationHistory")
);
const ViewStaff = React.lazy(() => import("src/pages/General/ViewStaff"));

const OfficeAccessories = React.lazy(() =>
  import("src/pages/General/OfficeAccessories")
);
const ScannerUploading = React.lazy(() =>
  import("src/pages/General/ScannerUploading")
);

const BioDataUpdateScannerById = React.lazy(() =>
  import("src/pages/General/BioDataUpdateScannerById")
);
const ShareTask = React.lazy(() => import("src/pages/General/ShareTask"));

const LoginHistory = React.lazy(() =>
  import("src/pages/UserFormData/LoginDevices")
);

// ----------------------------------------------------------------------

export default function Router() {
  const user = JSON.parse(localStorage.getItem("sections"));

  const routeArr = [
    // Auth
    {
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <LoginPage /> },
        { path: "register-unprotected", element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [
            { path: "reset-password", element: <ResetPasswordPage /> },
            { path: "new-password", element: <NewPasswordPage /> },
            // { path: "verify", element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    {
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "app", element: <GeneralAppPage /> },
        // { path: 'onlineuser', element: <NewOnlineUser type="onlineUsers" /> },
        // {
        //   path: "onlineuser/edit/:id",
        //   element: <ViewOnlineUser type="onlineUsers" />,
        // },
        // { path: 'onlineuser/preview/', element: <ImagePreview /> },
        // { path: 'deleteduser', element: <NewOnlineUser type="deletedUsers" /> },
        // { path: 'deleteduser/edit/:id', element: <ViewOnlineUser type="deletedUsers" /> },
        // { path: 'bannerpage', element: <BannerPage /> },
        // { path: 'mypackages', element: <MyPackages /> },
        // { path: 'biodatausers', element: <BioDataUsers /> },
        // { path: 'biodataentryforms', element: <BioDataEntryForms /> },
        // { path: 'hinducasteentries', element: <HinduCasteEntries /> },
        // { path: 'mystaff', element: <Mystaff /> },
        // { path: 'mobiles', element: <Mobiles /> },
        // { path: 'hindubiodata', element: <HinduBioData /> },
        // { path: 'offlineUsers', element: <OfflineUsers /> },
        // { path: 'offlineUsersadd', element: <OfflineUsersAdd /> },
        {
          path: "hindubiodataentryforms",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduBioDataEntryForms />{" "}
            </React.Suspense>
          ),
        },
        // { path: 'padadhikari', element: <React.Suspense
        // fallback={
        //   <div
        //     style={{
        //       height: "100vh",
        //       display: "flex",
        //       justifyContent: "center",
        //       alignItems: "center",
        //     }}
        //   >
        //     <LoadingScreenSmall />
        //   </div>
        // }> <Padadhikari /> </React.Suspense>},
        {
          path: "forms",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              <Forms />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "fieldapprovel",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <FieldApprovel />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "officeaccessories",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <OfficeAccessories />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "grouplinks",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <GroupLinks />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "assignhistory",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <AssignHistory />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "responsedata",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <ResponseData />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduresponsedata",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduResponseData />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "responseimportdata",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <ExcelImport />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "whatsappresponse",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <WhatsappResponse />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "whatsappgroupservices",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <WhatsappGroupServices />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "paidpromotion",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <PaidPromotion />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "socialmedia",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <SocialMedia />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduwhatsappresponse",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduWhatsappResponse />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduincoming",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduIncoming />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "incominguser",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <IncomingUser />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduincomingadd",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduIncomingAdd />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "whatsappresponseadd",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <WhatsappResponseAdd />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduwhatsappresponseadd",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduWhatsappResponseAdd />
            </React.Suspense>
          ),
        },
        {
          path: "message",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Message />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "notes",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Notes />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "fieldwork",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <FieldWork />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "fieldworkadd",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <FieldWorkAdd />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "countries",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Countries />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "states",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <States />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "cities",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Cities />{" "}
            </React.Suspense>
          ),
        },
        // division
        {
          path: "division",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Division />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "tehsils",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Tehsils />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "educations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Educations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "subeducations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Subeducations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "subsects",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Subsects />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "sects",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Sects />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "gotras",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Gotras />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "Hobbies",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Hobbies />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "MotherTongues",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <MotherTongues />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "Occupations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Occupations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "Suboccupations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Suboccupations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "Specialcollegespreferences",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Specialcollegespreferences />
            </React.Suspense>
          ),
        },
        {
          path: "Raashi",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Raashi />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "Starnakshatra",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Starnakshatra />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hindubiodataupdate/:id",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduBioDataUpdate />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "biodataupdate/:id",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <BioDataUpdate />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "biodataupdatescanner/:id",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <BioDataUpdateScannerById />
            </React.Suspense>
          ),
        },
        {
          path: "hinduforms",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Hinduforms />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinducountries",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduCountries />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hindustates",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduStates />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hindudivisions",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduDivisions />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinducities",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduCities />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hindutehsils",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduTehsils />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hindueducations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduEducations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduSubeducations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduSubeducations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduSubsects",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduSubsects />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduSects",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduSects />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hindugotras",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduGotras />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hinduoccupations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduOccupations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "hindusuboccupations",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduSuboccupations />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "globalsearch",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <GlobalSearch />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "pushNotification",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <PushNotification />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "/viewscannerdata/:type/:id",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <ViewScannerDetails />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "excelhistory",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <ExcelHistory />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "officeaccessoriesHistory/:id",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <OfficeAccessoriesHistory />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "addDeviceHistory/:id",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <AddDeviceHistory />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "userRequest",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <UserRequest />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "languageViewSection/:id",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <MessageView />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "addMessageSection",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <MessageSection />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "settings",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <Settings />{" "}
            </React.Suspense>
          ),
        },

        {
          path: "viewWorkFromHomeStaffTask/:type",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <ViewWorkFromHomeStaffTask />{" "}
            </React.Suspense>
          ),
        },
        {
          path: "viewHinduWorkFromHomeStaffTask/:type",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <ViewWorkFromHomeStaffTask />{" "}
            </React.Suspense>
          ),
        },

        {
          path: "viewStaffAssignTaskReport",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <ViewStaffAssignTaskReport />{" "}
            </React.Suspense>
          ),
        },

        {
          path: "scannerBiodataCopyHistory",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <ScannerBiodataCopyHistory />{" "}
            </React.Suspense>
          ),
        },

        {
          path: "hinduScannerBiodataCopyHistory",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduScannerBiodataCopyHistory />{" "}
            </React.Suspense>
          ),
        },

        {
          path: "scannerUploadReport",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <ScannerUploadReport />{" "}
            </React.Suspense>
          ),
        },

        // {
        //   path: "scannerUploadHindu",
        //   element: (
        //     <React.Suspense
        //       fallback={
        //         <div
        //           style={{
        //             height: "100vh",
        //             display: "flex",
        //             justifyContent: "center",
        //             alignItems: "center",
        //           }}
        //         >
        //           <LoadingScreenSmall />
        //         </div>
        //       }
        //     >
        //       {" "}
        //       <HinduScannerUploading />{" "}
        //     </React.Suspense>
        //   ),
        // },

        {
          path: "pushnotificationhistory",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <PushNotificationHistory />
            </React.Suspense>
          ),
        },
        {
          path: "download-history",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <DownlaodDataHistory />
            </React.Suspense>
          ),
        },
        {
          path: "analytics-history",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <AnalyticsHistory />
            </React.Suspense>
          ),
        },
        {
          path: "whatsappanalytics-history",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <WhatsAppAnalyticsHistory />
            </React.Suspense>
          ),
        },
        {
          path: "hinduPushNotificationHistory",
          element: (
            <React.Suspense
              fallback={
                <div
                  style={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LoadingScreenSmall />
                </div>
              }
            >
              {" "}
              <HinduPushNotificationHistory />
            </React.Suspense>
          ),
        },
      ],
    },

    // Main Routes
    {
      children: [
        { element: <Navigate to={"/auth/login"} replace />, index: true },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];

  if (user?.roles === "admin") {
    routeArr[1].children.push({
      path: "rolemanagement",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <RoleManagement />{" "}
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "bannerpage",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <BannerPage />{" "}
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "testUserHistory",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <TestUserHistory />{" "}
        </React.Suspense>
      ),
    });
    // routeArr[1].children.push({ path: 'excelhistory', element: <ExcelHistory /> })
  }

  if (user?.assignRole?.role === "Admin") {
    routeArr[1].children.push({
      path: "bannerpage",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <BannerPage />
        </React.Suspense>
      ),
    });
  }

  if (user?.assignRole?.role === "Admin" || user?.roles === "admin") {
    routeArr[1].children.push({
      path: "contactus",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ContactUs />
        </React.Suspense>
      ),
    });
  }

  if (user?.sections?.roles?.read) {
    routeArr[1].children.push({
      path: "rolemanagement",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <RoleManagement />
        </React.Suspense>
      ),
    });
  }
  if (user?.sections?.staffReport?.read) {
    routeArr[1].children.push({
      path: "staffreport",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <StaffReport />
        </React.Suspense>
      ),
    });
  }

  if (user?.roles === "master") {
    routeArr[1].children.push({
      path: "excelhistory",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ExcelHistory />
        </React.Suspense>
      ),
    });
    // routeArr[1].children.push({ path: "testUserHistory", element: <TestUserHistory /> })
  }
  if (user?.roles === "master") {
    routeArr[1].children.push({
      path: "excelhistory",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ExcelHistory />
        </React.Suspense>
      ),
    });
  }
  if (user?.sections?.onlineUsers?.read) {
    routeArr[1].children.push(
      {
        path: "onlineuser",
        element: (
          <React.Suspense
            fallback={
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingScreenSmall />
              </div>
            }
          >
            <NewOnlineUser type="onlineUsers" />
          </React.Suspense>
        ),
      },
      {
        path: "onlineuser/edit/:id",
        element: (
          <React.Suspense
            fallback={
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingScreenSmall />
              </div>
            }
          >
            {" "}
            <ViewOnlineUser type="onlineUsers" />
          </React.Suspense>
        ),
      },
      {
        path: "onlineuser/preview/",
        element: (
          <React.Suspense
            fallback={
              <div
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoadingScreenSmall />
              </div>
            }
          >
            {" "}
            <ImagePreview />
          </React.Suspense>
        ),
      }
    );
  }
  if (user?.sections?.onlineUsers?.read) {
    routeArr[1].children.push({
      path: "hinduonlineuser",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduOnlineUser type="hinduOnlineUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "hinduonlineuser/edit/:id",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduViewOnlineUser type="hinduOnlineUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "hinduonlineuser/preview/",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduImagePreview />
        </React.Suspense>
      ),
    });
  }
  if (user?.sections?.["Staff To Verify"]?.read || user?.roles === "admin") {
    routeArr[1].children.push({
      path: "onlineuser",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <NewOnlineUser type="onlineUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "pendingTask",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <PendingTask />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "newGlobalSearch",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <NewGlobalSearch />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "shareTask",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ShareTask />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "onlineuser/edit/:id",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ViewOnlineUser type="onlineUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "onlineuser/preview/",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ImagePreview />
        </React.Suspense>
      ),
    });
  }
  if (user?.sections?.DeletedUsers?.read) {
    routeArr[1].children.push({
      path: "deleteduser",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <NewOnlineUser type="deletedUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "deleteduser/edit/:id",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ViewOnlineUser type="deletedUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "hindudeleteduser",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduOnlineUser type="hinduDeletedUsers" />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "hindudeleteduser/edit/:id",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduViewOnlineUser type="hinduDeletedUsers" />
        </React.Suspense>
      ),
    });
  }
  if (user?.sections?.packages?.read)
    routeArr[1].children.push({
      path: "mypackages",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <MyPackages />
        </React.Suspense>
      ),
    });

  if (user?.sections?.bioDataUsers?.read)
    routeArr[1].children.push({
      path: "biodatausers",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <BioDataUsers />
        </React.Suspense>
      ),
    });
  if (user?.sections?.ScannerData?.read)
    routeArr[1].children.push({
      path: "scannerbiodatausers",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ScannerBioDataUsers />
        </React.Suspense>
      ),
    });

  if (
    user?.sections?.ScannerData?.create ||
    user?.sections?.ScannerData?.update ||
    user?.sections?.ScannerData?.isAdmin ||
    user?.sections?.HinduScannerData?.create ||
    user?.sections?.HinduScannerData?.update ||
    user?.sections?.HinduScannerData?.isAdmin
  )
    routeArr[1].children.push({
      path: "scannerUpload",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ScannerUploading />{" "}
        </React.Suspense>
      ),
    });
  if (user?.sections?.bioDataUsers?.create)
    routeArr[1].children.push({
      path: "biodataentryforms",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <BioDataEntryForms />
        </React.Suspense>
      ),
    });

  if (user?.sections?.hinduBioData?.read)
    routeArr[1].children.push({
      path: "hindubiodata",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduBioData />
        </React.Suspense>
      ),
    });
  if (user?.sections?.HinduScannerData?.read)
    routeArr[1].children.push({
      path: "hinduscannerbiodatausers",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduScannerBioDataUsers />
        </React.Suspense>
      ),
    });
  if (user?.sections?.hinduBioData?.create)
    routeArr[1].children.push({
      path: "hindubiodataentryforms",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <HinduBioDataEntryForms />
        </React.Suspense>
      ),
    });

  if (user?.sections?.staff?.read) {
    routeArr[1].children.push({
      path: "mystaff",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <Mystaff />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "mystaff/login/:id",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <LoginHistory />
        </React.Suspense>
      ),
    });
    routeArr[1].children.push({
      path: "mystaff/edit/:id",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <ViewStaff type="staff" />
        </React.Suspense>
      ),
    });
  }
  if (user?.sections?.mobiles?.read)
    routeArr[1].children.push({
      path: "mobiles",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <Mobiles />
        </React.Suspense>
      ),
    });

  if (user?.sections?.offlineUsers?.read)
    routeArr[1].children.push({
      path: "offlineUsers",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          <OfflineUsers />
        </React.Suspense>
      ),
    });
  if (user?.sections?.offlineUsers?.create)
    routeArr[1].children.push({
      path: "offlineUsersadd",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <OfflineUsersAdd />
        </React.Suspense>
      ),
    });

  if (user?.sections?.IncomingUsers?.read)
    routeArr[1].children.push({
      path: "incominguser",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <IncomingUser />
        </React.Suspense>
      ),
    });
  if (user?.sections?.IncomingUsers?.create)
    routeArr[1].children.push({
      path: "incominguseradd",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <IncomingUserAdd />
        </React.Suspense>
      ),
    });

  if (user?.sections?.padadhikari?.read)
    routeArr[1].children.push({
      path: "padadhikari",
      element: (
        <React.Suspense
          fallback={
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingScreenSmall />
            </div>
          }
        >
          {" "}
          <Padadhikari />
        </React.Suspense>
      ),
    });

  return useRoutes(routeArr);
}
