import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import constant from "src/Constant";
import moment from "moment";
import {
  Grid,
  Card,
  Button,
  CardContent,
  Typography,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "src/auth/useAuthContext";
import { copyBioDataCountHistoryReport } from "src/service";

export default function ScannerBioDataUsers() {
  const contxt = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [biodataCopyHistory, setBiodataCopyHistory] = useState([]);

  useEffect(() => {
    const fetchBiodataCopyHistoryReport = async () => {
      try {
        const response = await copyBioDataCountHistoryReport("hindu");
        setBiodataCopyHistory(response?.data?.data);
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    };

    fetchBiodataCopyHistoryReport();
  }, []);

  return (
    <>
      <Helmet>
        <title>Scanner Hindu Biodata Copy History : Master Admin</title>
      </Helmet>
      <Card>
        <CardHeader
          title={
            <Typography variant="h4">
             Hindu Biodata Copy History
            </Typography>
          }
          action={
            <Button
              variant="contained"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          }
        />
        <CardContent sx={{ pb: 3 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Biodata Id</TableCell>
                  <TableCell>Staff Name</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Copy Count</TableCell>
                  <TableCell>Date/Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {biodataCopyHistory.map((row,index) => (
                  <TableRow key={row.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.source_id}</TableCell>
                    <TableCell>{row.user_name}</TableCell>
                    <TableCell  style={{width: "100px", textAlign: "center"}}>
                      <img
                        src={`${constant.scannerUrl}/images/${row?.Image?.image}`}
                        style={{
                            width: "80%", 
                            height: "auto",
                            
                          }}
                      />
                    </TableCell>
                    <TableCell>{row.copy_count}</TableCell>
                    <TableCell>
                      (
                      {moment(row?.copy_date, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}{" "}
                      {moment(row?.copy_time, "HH:mm:ss").format("hh:mm A")})
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
