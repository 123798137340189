import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import {
  Grid,
  Card,
  Button,
  CardContent,
  Typography,
  CardHeader,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

import { staffNameApi, staffAssignTaskReportData } from "src/service";
import { useAuthContext } from "src/auth/useAuthContext";
import { AnalyticsWidgetSummary } from "src/sections/@dashboard/general/analytics";

const timingOption = [
  { label: "All", value: "all" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Before Yesterday", value: "beforeYesterday" },
  { label: "Last 3 Days", value: "lastThreeDays" },
  { label: "Last Week", value: "lastWeek" },
  { label: "Last Month", value: "lastMonth" },
  { label: "Last Year", value: "lastYear" },
];

const sourceTable = [
  { label: "Jain", value: "jain_data" },
  { label: "Hindu", value: "hindu_data" },
];

const reportType = [
  { label: "Upload", value: "upload" },
  { label: "Update", value: "update" },
];

export default function ScannerBioDataUsers({ type }) {
  const contxt = useAuthContext();
  const [staffName, setStaffName] = useState([]);
  const [permission, setPermission] = useState(null);
  const [filter, setFilter] = useState({
    role: contxt?.user?.sections?.ScannerData?.WorkFromHomeStaff
      ? "WorkFromHomeStaff"
      : contxt?.user?.sections?.ScannerData?.isAdmin
      ? "admin"
      : "staff",
    user_id: contxt?.user?.id,
  });

  const [uploadCount, setUploadCount] = useState({});
  const [updateCount, setUpdateCount] = useState({});

  useEffect(() => {
    fetchStaffName();
    setPermission(contxt?.user?.sections?.ScannerData);
  }, []);

  const fetchStaffName = async () => {
    try {
      const res = await staffNameApi();
      setStaffName(res?.data?.data || []);
    } catch (error) {
      console.error("Error fetching staff names:", error);
    }
  };

  const fetchStaffAssignTaskReport = async () => {
    try {
      const obj = {
        staff_id: filter?.staff_filter_id,
        timing: filter?.staff_filter_timing,
        source_table: filter?.source_table,
        report_type: filter?.report_type,
      };
      const response = await staffAssignTaskReportData(obj);
// console.log(response);
      if (response?.status === 200) {
        if (filter?.report_type === "upload") {
          setUploadCount(response?.data?.data[0] || {});
        } else if (filter?.report_type === "update") {
          setUpdateCount(response?.data?.data[0] || {});
        }
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Scanner Bio Data Users : Master Admin</title>
      </Helmet>
      <Card>
        <CardHeader
          title={<Typography variant="h4">Staff Report</Typography>}
        />
        <CardContent sx={{ pb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Staff</InputLabel>
                <Select
                  value={filter?.staff_filter_id || ""}
                  onChange={(e) =>
                    setFilter({ ...filter, staff_filter_id: e.target.value })
                  }
                >
                  {staffName?.map((item) => (
                    <MenuItem key={item?.id} value={item?.id}>
                      {item?.nameEnglish}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {permission?.isAdmin && (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Timing</InputLabel>
                    <Select
                      value={filter?.staff_filter_timing || ""}
                      onChange={(e) =>
                        setFilter({
                          ...filter,
                          staff_filter_timing: e.target.value,
                        })
                      }
                    >
                      {timingOption.map((item) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Type</InputLabel>
                    <Select
                      value={filter?.source_table || ""}
                      onChange={(e) =>
                        setFilter({ ...filter, source_table: e.target.value })
                      }
                    >
                      {sourceTable.map((item) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Report Type</InputLabel>
                    <Select
                      value={filter?.report_type || ""}
                      onChange={(e) =>
                        setFilter({ ...filter, report_type: e.target.value })
                      }
                    >
                      {reportType.map((item) => (
                        <MenuItem key={item?.value} value={item?.value}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={fetchStaffAssignTaskReport}
                  >
                    Apply Filter
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          {filter?.report_type === "upload" &&  (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6} md={3}>
                <AnalyticsWidgetSummary
                  title="Total Upload Data"
                  total={uploadCount?.upload_data}
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <AnalyticsWidgetSummary
                  title="Total Failed To Upload Data"
                  total={uploadCount?.failed_to_upload}
                 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <AnalyticsWidgetSummary
                  title="Total Duplicate Data"
                  total={uploadCount?.duplicate_data}
                  color="warning"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <AnalyticsWidgetSummary
                  title="Total Unique Data"
                  total={uploadCount?.unique_data}
                  color="success"
                />
              </Grid>
            </Grid>
          )}

          {/* uploadCount?.upload_data && */}
          {/* updateCount?.total_assign_task &&*/}

          {filter?.report_type === "update" &&  (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6} md={3}>
                <AnalyticsWidgetSummary
                  title="Total Assign Task"
                  total={updateCount?.total_assign_task}
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <AnalyticsWidgetSummary
                  title="Total Update Task"
                  total={updateCount?.update_task}
                  color="info"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <AnalyticsWidgetSummary
                  title="Total Approve Task"
                  total={updateCount?.approve_task}
                  color="success"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <AnalyticsWidgetSummary
                  title="Total Remove Task"
                  total={updateCount?.remove_task}
                />
              </Grid>
              {filter?.source_table === 'hindu_data' && (
                <Grid item xs={12} sm={6} md={3}>
                <AnalyticsWidgetSummary
                  title="Move To Jain"
                  total={updateCount?.move_to_jain}
                  color="warning"
                />
              </Grid>
              )}
              {filter?.source_table === 'jain_data' && (
                 <Grid item xs={12} sm={6} md={3}>
                 <AnalyticsWidgetSummary
                   title="Move To Hindu"
                   total={updateCount?.move_to_hindu}
                   color="warning"
                 />
              </Grid>
              )}
              
            
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
}
