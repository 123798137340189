import axios from "axios";
import constant from "./Constant";

const axiosInstance = axios.create({
  baseURL: `${constant.appBaseUrl}/api/`,
  // timeout: 10000,
});

const axiosInstanceScanner = axios.create({
  baseURL: `${constant.scannerUrl}/api/`,
  // timeout: 10000,
});
const axiosInstanceNodeScanner = axios.create({
  baseURL: `${constant.nodeScannerUrl}/api/`,
  // timeout: 10000,
});
axiosInstanceScanner.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);
axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("accessToken")}`;

const gpt_instance = axios.create({
  baseURL: "https://api.openai.com/v1/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer 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`,
  },
});

const scannerInstance = axios.create({
  baseURL: "https://scanner.jainshadimilan.com/api/",
  timeout: 10000,
});

export async function authorizeMe(token) {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export async function SendOtp(data) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("auth/send-otp", data)
      .then(function (response) {
        if (response?.data?.status) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

// export async function getOnlineUserList() {
//     var response = await axiosInstance.get('get-online-users')
//     return response;
// }

export async function signUp(data) {
  var response = await axiosInstance.patch("update-user", data);
  return response;
}

export async function newUser(data) {
  var response = await axiosInstance.post(`master-admin/new-account`, data);
  return response;
}
// hindu new user
export async function hinduNewUser(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-new-account`,
    data
  );
  return response;
}

export async function newUserStaffToPay(data) {
  var response = await axiosInstance.post(
    `master-admin/new-account-for-offline`,
    data
  );
  return response;
}

export async function banner() {
  var response = await axiosInstance.get(`master-admin/get-banners`);
  return response;
}
export async function hinduBanner() {
  var response = await axiosInstance.get(`master-admin/get-hindu-banners`);
  return response;
}

//doublw tick staff
export async function doubleDataStaffJain() {
  var response = await axiosInstance.get(
    `master-admin/get-all-double-tick-staff?type_of_staff=jain`
  );
  return response;
}
export async function doubleDataStaffHindu() {
  var response = await axiosInstance.get(
    `master-admin/get-all-double-tick-staff?type_of_staff=hindu`
  );
  return response;
}

export async function pacakgeList(data) {
  var response = await axiosInstance.post(
    `master-admin/get-pacakge-list`,
    data
  );
  return response;
}
export async function pacakgeHinduList(data) {
  var response = await axiosInstance.post(
    `master-admin/get-hindu-pacakge-list`,
    data
  );
  return response;
}
export async function isPhonePeOnApi(data) {
  var response = await axiosInstance.put(
    `master-admin/payment--method-update`,
    data
  );
  return response;
}
export async function getIsPhonePeOnApi() {
  var response = await axiosInstance.get(`master-admin/payment-method-status`);
  return response;
}

export async function addPacakge(data) {
  var response = await axiosInstance.post(
    `master-admin/add-package-by-form`,
    data
  );
  // var response = await axiosInstance.post(`master-admin/add-package-by-form`, data)
  return response;
}
export async function addHinduPacakge(data) {
  var response = await axiosInstance.post(
    `master-admin/add-hindu-package-by-form`,
    data
  );
  // var response = await axiosInstance.post(`master-admin/add-package-by-form`, data)
  return response;
}

export async function getAllPendingSectionList(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/list-all-section?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function getPendingRavcList(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/list-regist-approval-verify?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function pendingRavcCount(data) {
  var response = await axiosInstance.post(
    "master-admin/count-regist-approval-verify",
    data
  );
  return response;
}
export async function allPendingSectionCount(data) {
  var response = await axiosInstance.post(
    "master-admin/count-all-section",
    data
  );
  return response;
}

export async function updatePacakge(id, data) {
  // var response = await axiosInstance.patch(`master-admin/update-package`, data)
  var response = await axiosInstance.post(
    `master-admin/update-package-by-form/${id}`,
    data
  );
  return response;
}
export async function updateHinduPacakge(id, data) {
  // var response = await axiosInstance.patch(`master-admin/update-package`, data)
  var response = await axiosInstance.post(
    `master-admin/update-hindu-package-by-form/${id}`,
    data
  );
  return response;
}
export async function updatePacakgeStatus(data) {
  var response = await axiosInstance.patch(`master-admin/update-package`, data);
  return response;
}

export async function shareTaskCount(data) {
  var response = await axiosInstance.get(`share-to-pending/online/count`, data);
  return response;
}
export async function shareSectionCount(data) {
  var response = await axiosInstance.get(
    `share-to-pending/online/section-count`,
    data
  );
  return response;
}

export async function shareSectionList(data) {
  var response = await axiosInstance.post(
    `share-to-pending/online/section-lists`,
    data
  );
  return response;
}

export async function updateOnlyForMasterAdminApi(data) {
  var response = await axiosInstance.post(
    `master-admin/update-packages-viewkey-button`,
    data
  );
  return response;
}

export async function SplPkgStatus(data) {
  var response = await axiosInstance.post(
    `master-admin/update-packages-status-button`,
    data
  );
  return response;
}
export async function SplPkgStatusHindu(data) {
  var response = await axiosInstance.post(
    `master-admin/update-hindu-packages-status-button`,
    data
  );
  return response;
}

export async function deletePack(data) {
  var response = await axiosInstance.patch(`master-admin/delete-package`, data);
  return response;
}
export async function deleteHinduPack(data) {
  var response = await axiosInstance.patch(
    `master-admin/delete-hindu-package`,
    data
  );
  return response;
}

export async function bioDataDetail(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-bio-data?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function addBiodata(data) {
  var response = await axiosInstance.post(`master-admin/add-bio-data`, data);
  return response;
}

export async function updateBiodata(data) {
  var response = await axiosInstance.post(`master-admin/update-bio-data`, data);
  return response;
}

export async function shadiDoneMove(data) {
  var response = await axiosInstance.post(
    `offline/save-to-shadi-done-data`,
    data
  );
  return response;
}

export async function bioDataShadiDoneMove(data) {
  var response = await axiosInstance.post(
    `master-admin/save-to-shadi-done-data`,
    data
  );
  return response;
}

export async function deleteBiodata(data) {
  var response = await axiosInstance.post(`master-admin/delete-bio-data`, data);
  return response;
}
// ----------------------------Pushnotification-------------

export async function addPushNotification(data) {
  var response = await axiosInstance.post(
    `master-admin/push-notification`,
    data
  );
  return response;
}
export async function offPushNotification(data) {
  var response = await axiosInstance.post(
    `master-admin/pust-notification-status-button`,
    data
  );
  return response;
}

export async function SendPushNotification(data) {
  var response = await axiosInstance.post(`push-notification`, data);
  return response;
}

export async function SendPushNotificationById(data) {
  var response = await axiosInstance.post(`push-notification-by-id`, data);
  return response;
}

export async function getPushNotification() {
  var response = await axiosInstance.post(`master-admin/get-pust-notification`);
  return response;
}

export async function getExcelHistory() {
  var response = await axiosInstance.get(`offline/get-history-excel-download`);
  return response;
}

export async function updatePushNotification(data, id) {
  var response = await axiosInstance.post(
    `master-admin/update-push-notification/${id}`,
    data
  );
  return response;
}
export async function deletePushNotification(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-push-notification`,
    data
  );
  return response;
}
export async function getPushNotificationhistory() {
  var response = await axiosInstance.post(
    `master-admin/get-pust-notification-history`
  );
  return response;
}

// -----------------------------------------------------------

export async function deleteBiodataImage(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-bio-data-image`,
    data
  );
  return response;
}

export async function hinduBioDataAPi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/get?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function moveToSuspendFromHinduBioDataUsers(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/save-to-suspend-data`,
    data
  );
  return response;
}
export async function moveToDiffrentFromHinduBioDataUsers(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/save-to-diffrent-language`,
    data
  );
  return response;
}

export async function addHinduBiodata(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/add`,
    data
  );
  return response;
}
export async function updateUploadedHinduBiodata(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/update-uploads-hindu-bio-data`,
    data
  );
  return response;
}

export async function updateHinduBiodata(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/update`,
    data
  );
  return response;
}

export async function createFieldData(data) {
  var response = await axiosInstance.post(
    `master-admin/create-field-data`,
    data
  );
  return response;
}
export async function updateImagesDataScanner(data) {
  var response = await axiosInstance.post(
    `master-admin/get-bulk-image-data`,
    data
  );
  return response;
}
export async function createHinduFieldData(data) {
  var response = await axiosInstance.post(
    `master-admin/create-Hindu-field-data`,
    data
  );
  return response;
}
export async function updateToHinduFieldData(id, data) {
  var response = await axiosInstance.post(
    `master-admin/update-Hindu-field-data/${id}`,
    data
  );
  return response;
}
export async function updateFieldData(data, id) {
  var response = await axiosInstance.post(
    `master-admin/update-field-approval/${id}`,
    data
  );
  return response;
}
export async function updateHinduFieldData(data, id) {
  var response = await axiosInstance.post(
    `master-admin/update-Hindu-field-approval/${id}`,
    data
  );
  return response;
}
export async function deleteFieldData(id) {
  var response = await axiosInstance.post(
    `master-admin/delete-field-data/${id}`
  );
  return response;
}
export async function deleteHinduFieldData(id) {
  var response = await axiosInstance.post(
    `master-admin/delete-hindu-field-data/${id}`
  );
  return response;
}
export async function getFieldDataApi(data) {
  var response = await axiosInstance.post(`master-admin/get-field-data`, data);
  return response;
}
export async function getHinduFieldDataApi(data) {
  var response = await axiosInstance.post(
    `master-admin/get-Hindu-field-data`,
    data
  );
  return response;
}

export async function deleteHinduBiodata(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/delete`,
    data
  );
  return response;
}

export async function getOfflineUsers(page, limit, data, signal) {
  var response = await axiosInstance.post(
    `offline/all-users?limit=${limit}&page=${page}`,
    data,
    { signal }
  );
  return response;
}

export async function addOfflineUsers(data) {
  var response = await axiosInstance.post(`offline/create-user`, data);
  return response;
}

export async function addOfflineUsersCount(data) {
  var response = await axiosInstance.post(
    `offline/create-user-click-count`,
    data
  );
  return response;
}

export async function updateOfflineUsers(id, data) {
  var response = await axiosInstance.put(`offline/users/${id}`, data);
  return response;
}

export async function updateIncomingUsers(data) {
  var response = await axiosInstance.put(
    `master-admin/incoming-call/update`,
    data
  );
  return response;
}

export async function updateHinduIncomingUsers(id, data) {
  var response = await axiosInstance.put(
    `master-admin/hindu-incoming-call/update/${id}`,
    data
  );
  return response;
}

export async function groupLinkNote(data, id) {
  var response = await axiosInstance.post(`group-link/update/${id}`, data);
  return response;
}

// export async function deleteOfflineUsers(id) {
//     var response = await axiosInstance.post(`offline/users-delete/${id}`)
//     return response;
// }

export async function deleteOfflineUsers(data, id) {
  var response = await axiosInstance.post(`offline/users-delete/${id}`, data);
  return response;
}

export async function mobileTypeApi() {
  var response = await axiosInstance.get(`staff/get-mobiles?limit=${"100"}`);
  return response;
}

export async function getRoleManagement(page, limit) {
  var response = await axiosInstance.get(
    `master-admin/roles/get?page=${page}&limit=${"100"}`
  );
  return response;
}

export async function addRoleManagement(data) {
  var response = await axiosInstance.post(`master-admin/roles/add`, data);
  return response;
}

export async function deleteRoleManagement(data) {
  var response = await axiosInstance.post(`master-admin/roles/delete`, data);
  return response;
}

export async function updateRoleManagement(data) {
  var response = await axiosInstance.put(`master-admin/roles/update`, data);
  return response;
}

export async function sectionsArrayApi() {
  var response = await axiosInstance.get(`master-admin/roles/get-sections`);
  return response;
}

export async function addSubSectApi(data) {
  var response = await axiosInstance.post(`master-admin/add-sub-sect`, data);
  return response;
}

export async function getMessageByIdApi(id) {
  var response = await axiosInstance.get(`master-admin/get-messages-new/${id}`);
  return response;
}

export async function getOfficeAcceseriosByIdApi(id) {
  var response = await axiosInstance.get(`master-admin/view-history/${id}`);
  return response;
}
export async function getOfficeAcceseriosByStaff(id) {
  var response = await axiosInstance.get(
    `master-admin/get-staff-accessories-history/${id}`
  );
  return response;
}

// analytics api
export const getCountAnalyticStateType = async (
  startId,
  endId,
  fetchDate = null
) => {
  const today = new Date().toISOString().split("T")[0];

  try {
    const response = await axiosInstance.post(
      "offline/count-analytic-state-type",
      {
        fetch_date: fetchDate || today,
        startId: startId,
        endId: endId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching count analytic state type:", error);
    throw error;
  }
};

export async function getAddedDeviceByIdApi(id) {
  var response = await axiosInstance.get(
    `master-admin/adddevice-view-history/${id}`
  );
  return response;
}

export const getCountAnalyticSpecialType = async (
  startId,
  endId,
  fetchDate = null
) => {
  const today = new Date().toISOString().split("T")[0];

  try {
    const response = await axiosInstance.post(
      "offline/count-analytic-special-type",
      {
        fetch_date: fetchDate || today,
        startId: startId,
        endId: endId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching count analytic state type:", error);
    throw error;
  }
};

export const getCountAnalyticDataType = async (
  startId,
  endId,
  fetchDate = null
) => {
  const today = new Date().toISOString().split("T")[0];

  try {
    const response = await axiosInstance.post(
      "offline/count-analytic-data-type",
      {
        fetch_date: fetchDate || today,
        // startId: startId,
        // endId: endId
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching count analytic data type:", error);
    throw error;
  }
};

export const getCountAnalyticJainType = async (
  startId,
  endId,
  fetchDate = null
) => {
  const today = new Date().toISOString().split("T")[0];

  try {
    const response = await axiosInstance.post(
      "offline/count-analytic-jain-type",
      {
        fetch_date: fetchDate || today,
        startId: startId,
        endId: endId,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching count analytic jain type:", error);
    throw error;
  }
};

// whatsapp response analytics
export const getAnalyticResponseBio = async (fetchDate = null) => {
  const today = new Date().toISOString().split("T")[0];

  try {
    const response = await axiosInstance.post(
      "master-admin/get-analytics-data-type",
      {
        created_at: fetchDate || today,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching count analytic jain type:", error);
    throw error;
  }
};

export const getAnalyticResponse1009 = async (fetchDate = null) => {
  const today = new Date().toISOString().split("T")[0];

  try {
    const response = await axiosInstance.post(
      "master-admin/get-analytics-staff-1009-data",
      {
        created_at: fetchDate || today,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching count analytic jain type:", error);
    throw error;
  }
};

export const getAnalyticResponse1008 = async (fetchDate = null) => {
  const today = new Date().toISOString().split("T")[0];

  try {
    const response = await axiosInstance.post(
      "master-admin/get-analytics-staff-1008-data",
      {
        created_at: fetchDate || today,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching count analytic jain type:", error);
    throw error;
  }
};

export const getAnalyticResponseReply = async (fetchDate = null) => {
  const today = new Date().toISOString().split("T")[0];

  try {
    const response = await axiosInstance.post(
      "master-admin/get-analytics-data-user-replay",
      {
        created_at: fetchDate || today,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching count analytic jain type:", error);
    throw error;
  }
};

export async function getStaffApi(page, limit, data) {
  var response = await axiosInstance.post(
    `staff/get-staff?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getStaffApiTest(page, limit, data) {
  var response = await axiosInstance.post(
    `staff/get-staffs?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function getStaffAccessoriesHistory(id) {
  var response = await axiosInstance.get(
    `master-admin/get-staff-accessories-history/${id}`
  );
  return response;
}

// export async function getStaffShareToData(data) {
//   var response = await axiosInstance.get(
//     `staff/get-staff/share-to-list/${data}`
//   );
//   return response;
// }

export async function getStaffShareToData(data, page, limit) {
  var response = await axiosInstance.post(
    `staff/get-staff?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function getSameMobileData() {
  var response = await axiosInstance.post(`master-admin/get-same-mobile`);
  return response;
}
export async function addStaff(data) {
  var response = await axiosInstance.post(`staff/add-staff`, data);
  return response;
}

export async function MobileApi(page, limit, data) {
  var response = await axiosInstance.post(
    `staff/get-mobiles?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addMobiles(data) {
  var response = await axiosInstance.post(`staff/add-mobiles`, data);
  return response;
}

export async function updateMobiles(data) {
  var response = await axiosInstance.post(`staff/update-mobile`, data);
  return response;
}

export async function staffNameApi() {
  var response = await axiosInstance.get(`master-admin/get-all-staff`);
  return response;
}
export async function updateMobilesStatus(id) {
  var response = await axiosInstance.get(`staff/update-mobile-status/${id}`);
  return response;
}

export async function companyNameApi() {
  var response = await axiosInstance.get(`master-admin/company-names`);
  return response;
}

export async function assignStaffNameApi() {
  var response = await axiosInstance.post(`master-admin/get-all-staffs`);
  return response;
}

export async function getAllHinduSectsApi() {
  var response = await axiosInstance.get(
    `master-admin/hindu-forms/get-hindu-all-sect`
  );
  return response;
}

export async function getAllHinduSubSectsApi(sect_id) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/get-all-hindu-sub-Sect`,
    sect_id
  );
  return response;
}

export async function deleteStaffMobile(data) {
  var response = await axiosInstance.post(`staff/delete-mobile`, data);
  return response;
}

export async function getPadadhikariApi(page, limit) {
  var response = await axiosInstance.post(
    `master-admin/get-padh-adhikari?page=${page}&limit=${limit}`
  );
  return response;
}

export async function addPadadhikariApi(data) {
  var response = await axiosInstance.post(
    `master-admin/add-padh-adhikari`,
    data
  );
  return response;
}

export async function updatePadadhikariApi(id, data) {
  var response = await axiosInstance.put(
    `master-admin/update-padh-adhikari/${id}`,
    data
  );
  return response;
}

export async function countStaffReportApi(data) {
  var response = await axiosInstance.post(
    `staff-report/get-admin-staff-reports-count-1`,
    data
  );
  return response;
}
export async function countStaffReportApi2(data) {
  var response = await axiosInstance.post(
    `staff-report/get-admin-staff-reports-count-2`,
    data
  );
  return response;
}

export async function staffReportListApi(data, signal) {
  // var response = await axiosInstance.post(`staff-report/get-admin-staff-list?page=${page}&limit=${"5"}`, data)
  var response = await axiosInstance.post(
    `staff-report/get-current-task-report-staff`,
    data,
    { signal }
  );
  return response;
}
export async function getStaffViewTaskApi(data) {
  var response = await axiosInstance.post(
    `staff-report/get-current-task-all-report-staff`,
    data
  );
  return response;
}

export async function getStaffCallRecordApi(data) {
  var response = await axiosInstance.post(`staff-report/get-recording`, data);
  return response;
}
export async function loginStatusApi(data) {
  var response = await axiosInstance.post(
    `master-admin/get-login-status`,
    data
  );
  return response;
}
export async function updateloginStatusApi(id) {
  var response = await axiosInstance.get(
    `master-admin/update-login-status/${id}`
  );
  return response;
}
export async function staffReportListCountApi(page, limit, data) {
  var response = await axiosInstance.post(
    `staff-report/get-admin-staff-list?page=${page}&limit=${"5"}`,
    data
  );
  return response;
}

export async function getHinduWhatsappResponseApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-hindu-whatsapp-responses?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

// response data api
export async function getWhatsappResponseApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-whatsapp-responses?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
// hindu whatsapp response
export async function HinduWhatsappResponseApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-hindu-whatsapp-responses?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function getGroupServicesApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-whatsapp-group-response?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getHinduGroupServicesApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-responce/get-whatsapp-group-response?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getResponseSocialMediaApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-social-media?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getPaidPromotionApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-paid-promotion?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
// hindu paid promotion
export async function getHinduPaidPromotionApi(page, limit, data) {
  var response = await axiosInstance.post(
    `/master-admin/hindu-responce/get-paid-promotion?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getSocialMediaApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-social-media?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getHinduSocialMediaApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-responce/get-social-media?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

// response data create apis
export async function addWhatsappResponseApi(data) {
  var response = await axiosInstance.post(
    `master-admin/create-whatsapp-response`,
    data
  );
  return response;
}
export async function addGroupServiceApi(data) {
  var response = await axiosInstance.post(
    `master-admin/create-whatsapp-group-response`,
    data
  );
  return response;
}
export async function addSocialMediaApi(data) {
  var response = await axiosInstance.post(
    `master-admin/create-social-media`,
    data
  );
  return response;
}
export async function addPaidPromotionApi(data) {
  var response = await axiosInstance.post(
    `master-admin/create-paid-promotion`,
    data
  );
  return response;
}
// incoming section
export async function addIncomingCallApi(data) {
  var response = await axiosInstance.post(
    `/master-admin/incoming-call/create`,
    data
  );
  return response;
}
export async function addHinduIncomingCallApi(data) {
  var response = await axiosInstance.post(
    `/master-admin/hindu-incoming-call/create`,
    data
  );
  return response;
}

export async function getIncomingCallApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/incoming-call/get?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getHinduIncomingApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-incoming-call/get?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addHinduWhatsappResponseApi(data) {
  var response = await axiosInstance.post(
    `master-admin/create-hindu-whatsapp-response`,
    data
  );
  return response;
}
// response data update api
export async function updateWhatsappResponseApi(data) {
  var response = await axiosInstance.put(
    `master-admin/update-whatsapp-response`,
    data
  );
  return response;
}
export async function updateHinduWhatsappResApi(data) {
  var response = await axiosInstance.put(
    `master-admin/update-hindu-whatsapp-response`,
    data
  );
  return response;
}
export async function updateGroupServicesApi(data) {
  var response = await axiosInstance.put(
    `master-admin/update-whatsapp-group-response`,
    data
  );
  return response;
}
// hindu
export async function updateHinduGroupServicesApi(data) {
  var response = await axiosInstance.put(
    `master-admin/hindu-responce/create-whatsapp-group-response`,
    data
  );
  return response;
}
export async function updateSocialMediaApi(data) {
  var response = await axiosInstance.put(
    `master-admin/update-social-media`,
    data
  );
  return response;
}
export async function updatePaidPromotionApi(data) {
  var response = await axiosInstance.put(
    `master-admin/update-paid-promotion`,
    data
  );
  return response;
}

export async function updateHinduWhatsappResponseApi(data) {
  var response = await axiosInstance.put(
    `master-admin/update-hindu-whatsapp-response`,
    data
  );
  return response;
}
// response data detele api
export async function deleteWhatsappResponseApi(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-whatsapp-response`,
    data
  );
  return response;
}
export async function deleteHinduWhatsappResApi(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-hindu-whatsapp-response`,
    data
  );
  return response;
}
export async function deleteWhatsappGroupServiceApi(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-whatsapp-group-response`,
    data
  );
  return response;
}
export async function deleteSocialMediaApi(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-social-media`,
    data
  );
  return response;
}
export async function deletePaidPromotionApi(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-paid-promotion`,
    data
  );
  return response;
}

export async function deleteHinduWhatsappResponseApi(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-hindu-whatsapp-response`,
    data
  );
  return response;
}

export async function mandirListApi() {
  var response = await axiosInstance.post(`form/get-mandir-list?limit=805`);
  return response;
}

export async function numberApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-staff-numbers?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addNumberApi(data) {
  var response = await axiosInstance.post(
    `master-admin/add-staff-number`,
    data
  );
  return response;
}

export async function updateNumber(data) {
  var response = await axiosInstance.post(
    `master-admin/update-staff-number`,
    data
  );
  return response;
}

export async function deleteNumber(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-staff-number`,
    data
  );
  return response;
}

export async function profileApi(id, data) {
  var response = await axiosInstance.post(
    `master-admin/get-profile-list/${id}`,
    data
  );
  return response;
}
// hindu profile
export async function HinduProfileApi(id, data) {
  var response = await axiosInstance.post(
    `master-admin/get-hindu-profile-list/${id}`,
    data
  );
  return response;
}

export async function actionProfilePictureApi(data) {
  var response = await axiosInstance.post(`master-admin/action`, data);
  return response;
}
export async function actionHinduProfilePictureApi(data) {
  var response = await axiosInstance.post(`master-admin/action-hindu`, data);
  return response;
}

export async function uploadProfile(id, data) {
  var response = await axiosInstance.post(`master-admin/upload/${id}`, data);
  return response;
}
// hindu upload
export async function uploadHinduProfile(id, data) {
  var response = await axiosInstance.post(
    `master-admin/upload-hindu/${id}`,
    data
  );
  return response;
}

export async function payment(id, data) {
  var response = await axiosInstance.post(`master-admin/payment/${id}`, data);
  return response;
}

export async function updateMasking(id) {
  var response = await axiosInstance.get(
    `master-admin/update-number-masking/${id}`
  );
  return response;
}
export async function paymentHindu(id, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-payment/${id}`,
    data
  );
  return response;
}
export async function paymentGenLink(id, data) {
  var response = await axiosInstance.post(
    `payment/admin/create-payment-link/${id}`,
    data
  );
  return response;
}
export async function paymentGenLinkHindu(id, data) {
  var response = await axiosInstance.post(
    `payment/admin/create-payment-link-hindu/${id}`,
    data
  );
  return response;
}
export async function getDeviceDataById(id, data) {
  var response = await axiosInstance.post(
    `master-admin/get-login-device-history/${id}`,
    data
  );
  return response;
}
export async function getSectionHistoryById(id) {
  var response = await axiosInstance.get(
    `master-admin/get-history-access-staff/${id}`
  );
  return response;
}

export async function deletePaymentCard(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-last-payment`,
    data
  );
  return response;
}
export async function deletePaymentCardHindu(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-hindu-last-payment`,
    data
  );
  return response;
}

export async function getAuditApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/user-history?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function interestApi(data) {
  const response = await axiosInstance.post(
    "master-admin/user-profile-detail",
    data
  );
  return response;
}

export async function signIn(data) {
  const response = await axiosInstance.post("auth/logins", data);
  return response;
}

export async function subSectApi(sect) {
  var response = await axiosInstance.get(`get-sub-sect-list/${sect}`);
  return response;
}

export async function gotraApi(data) {
  var response = await axiosInstance.get(`get-gotra-list/${data}`);
  return response;
}

export async function addGotraApi(data) {
  var response = await axiosInstance.post(`master-admin/add-gotra`, data);
  return response;
}

export async function restoreApi(id) {
  var response = await axiosInstance.get(`master-admin/restore-user/${id}`);
  return response;
}
// Hindu Restore api
export async function HinduRestoreApi(id) {
  var response = await axiosInstance.get(
    `master-admin/restore-hindu-user/${id}`
  );
  return response;
}

export async function referralPending(id) {
  var response = await axiosInstance.get(
    `master-admin/get-referral/panding-userList/${id}`
  );
  return response;
}

export async function referralHistory(id) {
  var response = await axiosInstance.get(
    `master-admin/get-referral/history-userList/${id}`
  );
  return response;
}

export async function updateUser(id, data) {
  var response = await axiosInstance.patch(
    `master-admin/update-user/${id}`,
    data
  );
  return response;
}

export async function createSectionAccessHistoryApi(id, data) {
  var response = await axiosInstance.patch(
    `master-admin/update-staff-access-history/${id}`,
    data
  );
  return response;
}

export async function updateHinduUser(id, data) {
  var response = await axiosInstance.patch(
    `master-admin/hinud-update-user/${id}`,
    data
  );
  return response;
}

export async function updateApprovalPopup(data) {
  var response = await axiosInstance.post(
    `master-admin/update-isapprovalpopup-open`,
    data
  );
  return response;
}
export async function updateApprovalPopupHindu(data) {
  var response = await axiosInstance.post(
    `master-admin/update-isapprovalpopup-open-hindu`,
    data
  );
  return response;
}

export async function updateMandir(data) {
  var response = await axiosInstance.post(
    `master-admin/add-mandir-details`,
    data
  );
  return response;
}

export async function getMandirlist(id, data) {
  var response = await axiosInstance.get(
    `master-admin/get-mandir-details/${id}`,
    data
  );
  return response;
}

export async function updateOtpApi(id) {
  var response = await axiosInstance.patch(`master-admin/update-otp/${id}`);
  return response;
}

export async function updatePasswordAPi(data) {
  var response = await axiosInstance.patch(
    `master-admin/update-password`,
    data
  );
  return response;
}
export async function updatePasswordAPiNew(data) {
  // var response = await axiosInstance.patch(
  //   `auth/update-passwordnew`,
  //   data
  // );
  var response = await axiosInstance.patch(
    `master-admin/update-password-new/${data.id}`,
    data
  );
  return response;
}

export async function deleteUser(id, data) {
  var response = await axiosInstance.patch(
    `master-admin/delete-user/${id}`,
    data
  );
  return response;
}
export async function deleteHinduUser(id, data) {
  var response = await axiosInstance.patch(
    `master-admin/hindu-delete-user/${id}`,
    data
  );
  return response;
}

export async function verifyOtp(data) {
  var response = await axiosInstance.post("auth/otp-verify", data);
  return response;
}

export async function addToFavoriteList(data) {
  var response = await axiosInstance.get(`add-to-favourite/${data.id}`);
  return response;
}

export async function me() {
  var response = await axiosInstance.get(`me`);
  return response;
}

// export async function getOnlineUserList(page, limit, filter) {
//     var response = await axiosInstance.post(`master-admin/get-online-users?page=${page}&limit=${limit}`, filter)
//     return response;
// };
export async function getOnlineUserList(page, limit, filter, signal) {
  const filteredRoles = filter.roles
    ? filter.roles.filter((role) => role !== "staff")
    : [];
  const updatedFilter = {
    ...filter,
    roles: filteredRoles,
  };
  const response = await axiosInstance.post(
    `master-admin/get-online-users?page=${page}&limit=${limit}`,
    updatedFilter,
    { signal }
  );
  return response;
}

// global search
export async function UniversalSearchUser(data) {
  var response = await axiosInstance.post(
    `global/search-user-by-section`,
    data
  );
  return response;
}
export async function getHinduOnlineUserList(page, limit, filter) {
  const filteredRoles = filter.roles
    ? filter.roles.filter((role) => role !== "staff")
    : [];
  const updatedFilter = {
    ...filter,
    roles: filteredRoles,
  };
  const response = await axiosInstance.post(
    `master-admin/get-hindu-online-users?page=${page}&limit=${limit}`,
    updatedFilter
  );
  return response;
}

export async function getOnlineUserAnalytics() {
  var response = await axiosInstance.get(`master-admin/get-users-count`);
  return response;
}

export async function getCronCount() {
  var response = await axiosInstance.post(`master-admin/get-image-data-count`);
  return response;
}
// incoming biodata recieve
export async function getIncomingCount() {
  var response = await axiosInstance.post(
    `master-admin/incoming-call/get-count-all-type`
  );
  return response;
}

export async function getCronCountHindu() {
  var response = await axiosInstance.post(
    `master-admin/get-image-data-count-hindu`
  );
  return response;
}

export async function getAccessoriesCount(data) {
  var response = await axiosInstance.post(
    `master-admin/get-accessories-count`,
    data
  );
  return response;
}
export async function getSameNumberDataApi(page, limit) {
  var response = await axiosInstance.get(
    `master-admin/get-same-number?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getAccessoriesData(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/accessories?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function deleteAccessories(data) {
  var response = await axiosInstance.delete(
    `master-admin/delete-accessories/${data}`
  );
  return response;
}
export async function assignAccessories(data) {
  var response = await axiosInstance.post(
    `master-admin/create-accessories`,
    data
  );
  return response;
}
export async function updateAccessories(id, data) {
  var response = await axiosInstance.put(
    `master-admin/update-accessories/${id}`,
    data
  );
  return response;
}
// Hindu user Analytics
export async function getHinduOnlineUserAnalytics() {
  var response = await axiosInstance.get(`master-admin/get-hindu-users-count`);
  return response;
}
export async function getResonseDataUserAnalytics() {
  var response = await axiosInstance.get(
    `/master-admin/count-all-section-response-data`
  );
  return response;
}

export async function getProfileById(id) {
  var response = await axiosInstance.get(`master-admin/get-user/${id}`);
  return response;
}
// hindu profile id api
export async function getHinduProfileById(id) {
  var response = await axiosInstance.get(`master-admin/get-hindu-user/${id}`);
  return response;
}

export async function getStaffNumberAPI(data) {
  var response = await axiosInstance.post(
    `master-admin/get-staff-numbers`,
    data
  );
  return response;
}
export async function getIncomingBioListAPI(data) {
  var response = await axiosInstance.post(
    `master-admin/incoming-call/get`,
    data
  );
  return response;
}

export async function getRaashiList() {
  var response = await axiosInstance.get("form-detail/getRaashiList");
  return response;
}
export async function getSectList() {
  var response = await axiosInstance.get("form-detail/go-live");
  return response;
}

export async function getSubRaashiList(id) {
  var response = await axiosInstance.get(
    `form-detail/get-sub-raashi-list/${id}`
  );
  return response;
}

export async function getDetailList() {
  var response = await axiosInstance.get("form-detail/go-live");
  return response;
}

export async function fetchAssignHistory({
  date,
  staffId,
  page = 0,
  limit = 10,
}) {
  // Construct query parameters
  const params = {
    group_link_assign_at: date, // Required: Assign Date
    page, // Default Page
    limit, // Default Limit
  };

  // Conditionally add staff ID if it's provided
  if (staffId) {
    params.group_link_assign_to_sta = staffId;
  }

  // Make the GET request
  const response = await axiosInstance.get("group-link/get-assign-history", {
    params,
  });

  // Return the full response object
  return response;
}

export async function getCountryListAPI() {
  var response = await axiosInstance.get(`get-country-list`);
  return response;
}

export async function getStateListAPI(countryId) {
  var response = await axiosInstance.get(
    `get-state-list/${countryId}?limit=100`
  );
  return response;
}
export async function getStateListHinduAPI(countryId) {
  var response = await axiosInstance.get(
    `master-admin/hindu-forms/get-state-list-hindu/${countryId}?limit=100`
  );
  return response;
}

export async function getCityListAPI(stateid) {
  var response = await axiosInstance.get(`get-city-list/${stateid}`);
  return response;
}

export async function getTehsilListAPI(cityid) {
  var response = await axiosInstance.get(`get-tehsil-list/${cityid}?limit=100`);
  return response;
}

export async function getCurrentState() {
  var response = await axiosInstance.get(`http://ip-api.com/json/`);
  return response;
}

export async function getPackgeList(id) {
  var response = await axiosInstance.get(`master-admin/get-pacakge-list/${id}`);
  return response;
}
export async function getPackgeHinduList(id) {
  var response = await axiosInstance.get(
    `master-admin/get-pacakge-list-hindu/${id}`
  );
  return response;
}
export async function getBasicPackgeList() {
  var response = await axiosInstance.get(`get-pacakge-list`);
  return response;
}
export async function getOfflineDataCountApi() {
  var response = await axiosInstance.get(`offline/get-count`);
  return response;
}
export async function getBiodataDataCountApi() {
  var response = await axiosInstance.get(`master-admin/get-count`);
  return response;
}
export async function getResponseDataCountApi() {
  var response = await axiosInstance.get(`master-admin/get-shadi-done-count`);
  return response;
}

export async function getSpecialPackgeList() {
  var response = await axiosInstance.get(`new-special-pacakge-list`);
  return response;
}

export async function getVipPackgeList(id) {
  var response = await axiosInstance.get(`new-vip-pacakge-list/${id}`);
  return response;
}

export async function getLastPayment(id) {
  var response = await axiosInstance.get(`master-admin/last-payment/${id}`);
  return response;
}

export async function getLastPaymentHindu(id) {
  var response = await axiosInstance.get(
    `master-admin/last-hindu-payment/${id}`
  );
  return response;
}

export async function createOffer(data) {
  var response = await axiosInstance.post(
    `master-admin/create-offer-for-user`,
    data
  );
  return response;
}
export async function createHinduOffer(data) {
  var response = await axiosInstance.post(
    `master-admin/create-offer-for-hindu-user`,
    data
  );
  return response;
}
export async function getMyOffers(id) {
  var response = await axiosInstance.get(`master-admin/get-offers/${id}`);
  return response;
}

export async function getMyOffersHindu(id) {
  var response = await axiosInstance.get(`master-admin/get-hindu-offers/${id}`);
  return response;
}

export async function getMyOfferHistory(id) {
  var response = await axiosInstance.get(`master-admin/get-all-offers/${id}`);
  return response;
}

export async function deleteOffer(data) {
  var response = await axiosInstance.patch(`master-admin/delete-offer`, data);
  return response;
}

export async function getReferralPandingUserList(id) {
  var response = await axiosInstance.get(`get-referral/panding-userList/${id}`);
  return response;
}

export async function updateMessageHeadingApi(id, data) {
  var response = await axiosInstance.post(
    `master-admin/update-heading-update/${id}`,
    data
  );
  return response;
}

// export async function updateMessageHeadingApi(id, data) {
//   const response = await axiosInstance.post(`master-admin/update-heading-update/${id}`, data);
//   return response;
// }

export async function getNotes() {
  var response = await axiosInstance.post(`master-admin/get-notes`, {
    type: "other",
  });
  return response;
}
export async function getWhatsAppMessageApi(page, limit, data) {
  // export async function getWhatsAppMessageApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-messages-new-admin?page=${page}&limit=${limit}`,
    data
  );
  // var response = await axiosInstance.post(`master-admin/get-messages?page`);
  // var response = await axiosInstance.post(`master-admin/get-messages?page=${page}&limit=${limit}`, data)
  return response;
}

export async function uploadBiodata(data) {
  var response = await axiosInstance.post(
    `master-admin/uploads-bio-data`,
    data
  );
  return response;
}
export async function uploadImageData(data) {
  var response = await axiosInstance.post(
    `master-admin/upload-image-data`,
    data
  );
  return response;
}
export async function uploadImageDataHindu(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-upload-image-data`,
    data
  );
  return response;
}
export async function uploadBiodataHindu(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/uploads-hindu-bio-data`,
    data
  );
  return response;
}
export async function sameImage() {
  var response = await axiosInstance.post(`master-admin/get-same-image-name`);
  return response;
}

export async function sameMobile() {
  var response = await axiosInstance.post(`offline/same-mobile-user`);
  return response;
}

export async function taskAssign(data) {
  var response = await axiosInstance.post(`master-admin/task-assign`, data);
  return response;
}

export async function taskAssignFromHinduOnlineUsers(data) {
  var response = await axiosInstance.post(`task-admin/create-Hindu-task`, data);
  return response;
}

export async function taskAssignDoubleTick(data) {
  var response = await axiosInstance.post(
    `doubleClick/MA-reassign-to-staff`,
    data
  );
  return response;
}
export async function popupShowCard(data) {
  var response = await axiosInstance.post(
    `global/search-user-by-section`,
    data
  );
  return response;
}

export async function taskAssignFromOnlineUsers(data) {
  var response = await axiosInstance.post(`task-admin/create-task`, data);
  return response;
}
export async function taskAssignFromResponseUsers(data) {
  var response = await axiosInstance.post(`task-admin/create-task`, data);
  return response;
}

//Feedback
export async function feedBackAssign(data) {
  var response = await axiosInstance.post(`task-admin/create-task`, data);
  return response;
}
export async function otpSubmit(data) {
  var response = await axiosInstance.post(`staff/send-otp`, data);
  return response;
}

export async function onAppPopUp(data) {
  var response = await axiosInstance.post(
    `global/search-user-by-section`,
    data
  );
  return response;
}

export async function updateUploadedBiodata(data) {
  var response = await axiosInstance.post(
    `master-admin/update-uploads-bio-data`,
    data
  );
  return response;
}
export async function updateUploadedBiodataScanner(data) {
  var response = await axiosInstance.post(
    `master-admin/update-uploads-bio-data-for-scanner`,
    data
  );
  return response;
}

export async function testUserHistory(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-test-history?${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function deleteTask(data) {
  var response = await axiosInstance.post(`staff/delete-task`, data);
  return response;
}

export async function getBiodataApi(data) {
  var response = await axiosInstance.post(
    `master-admin/get-uploads-bio-data`,
    data
  );
  return response;
}

export async function updateBiodataCount() {
  // var response = await axiosInstance.post(
  //   `master-admin/single-task-details`,
  // );
  // return response;
}
export async function updateBiodataImgTask(data) {
  var response = await axiosInstance.post(
    `task-admin/get-biodata-image-task`,
    data
  );
  return response;
}
export async function updateBiodataImgTaskScanner(data) {
  var response = await axiosInstance.post(
    `task-admin/get-biodata-scanner-task`,
    data
  );
  return response;
}
export async function getBiodataImgCount(data) {
  var response = await axiosInstance.post(
    `master-admin/get-details-task-and-work`,
    data
  );
  return response;
}

export async function removeBiodataCount() {
  var response = await axiosInstance.post(
    `master-admin/count-remove-uploads-bio-data`
  );
  return response;
}

export async function removeBiodata(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-uploads-bio-data`,
    data
  );
  return response;
}

export async function getIdListApi(data) {
  var response = await axiosInstance.post(`staff/get-list-ids`, data);
  return response;
}

export async function updateMessageApi(id, data) {
  var response = await axiosInstance.put(
    `master-admin/update-message/${id}`,
    data
  );
  return response;
}

export async function addWhatsAppMessageApi(data) {
  var response = await axiosInstance.post(
    `master-admin/create-message-new`,
    data
  );
  // var response = await axiosInstance.post(`master-admin/create-message`, data);
  return response;
}

export async function getTotalData() {
  var response = await axiosInstance.post(
    `master-admin/image-upload-data-details`
  );
  return response;
}

export async function getTotalHinduData() {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/hindu-image-upload-data-details`
  );
  return response;
}

export async function deleteMessageApi(data) {
  var response = await axiosInstance.post(`master-admin/delete-message`, data);
  return response;
}
export async function deleteLanguageApi(data) {
  var response = await axiosInstance.delete(
    `master-admin/delete-language/${data}`
  );
  return response;
}
export async function getNotesApi(page, limit) {
  var response = await axiosInstance.post(
    `master-admin/get-note-list?page=${page}&limit=${limit}`
  );
  return response;
}

export async function addNotesApi(data) {
  var response = await axiosInstance.post(`master-admin/create-note`, data);
  return response;
}

export async function updateNotesApi(data) {
  var response = await axiosInstance.put(`master-admin/update-note`, data);
  return response;
}

export async function deleteNotesApi(data) {
  var response = await axiosInstance.post(`master-admin/delete-note`, data);
  return response;
}

export async function getFieldWorkApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-offline-user-clone?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addFieldWorkApi(data) {
  var response = await axiosInstance.post(
    `master-admin/create-offline-user-clone`,
    data
  );
  return response;
}

export async function updateFieldWorkApi(data) {
  var response = await axiosInstance.post(
    `master-admin/update-offline-user-clone`,
    data
  );
  return response;
}

export async function deleteFieldWorkApi(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-offline-user-clone`,
    data
  );
  return response;
}

export async function getFormsAnalyticsApi() {
  var response = await axiosInstance.get(`form/get-form-counts`);
  return response;
}

export async function CountryListApi(page, limit, data) {
  var response = await axiosInstance.post(
    `form/get-country-list?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function updateCountryListApi(id, data) {
  var response = await axiosInstance.put(`form/update-country/${id}`, data);
  return response;
}

export async function deleteCountryListApi(id) {
  var response = await axiosInstance.post(`form/delete-country/${id}`);
  return response;
}
export async function restoreOfflineUser(id) {
  var response = await axiosInstance.post(
    `offline/restore-suspended-users/${id}`
  );
  return response;
}
export async function restoreBiodataUser(id) {
  var response = await axiosInstance.post(
    `master-admin/restore-suspended-users/${id}`
  );
  return response;
}

export async function addCountryApi(data) {
  var response = await axiosInstance.post(`form/create-country`, data);
  return response;
}

export async function StateListApi(id, page, limit, data) {
  var response = await axiosInstance.post(
    `form/get-state-list/${id}?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addStateApi(data) {
  var response = await axiosInstance.post(`form/create-state`, data);
  return response;
}

export async function updateStateListApi(id, data) {
  var response = await axiosInstance.put(`form/update-state/${id}`, data);
  return response;
}

export async function deleteStateListApi(id) {
  var response = await axiosInstance.post(`form/delete-state/${id}`);
  return response;
}

export async function cityListApi(id, page, limit) {
  var response = await axiosInstance.get(
    `form/get-city-list/${id}?page=${page}&limit=${limit}`
  );
  return response;
}
export async function divisionListApi(id, page, limit) {
  var response = await axiosInstance.post(
    `form/get-division-list/${id}?page=${page}&limit=${limit}`
  );
  return response;
}

export async function deleteCityListApi(data) {
  var response = await axiosInstance.post(`form/delete-city`, data);
  return response;
}
export async function deleteDivisonListApi(id) {
  var response = await axiosInstance.post(`form/delete-division/${id}`);
  return response;
}

export async function addCityApi(data) {
  var response = await axiosInstance.post(`form/create-city`, data);
  return response;
}
export async function addDivisonApi(data) {
  var response = await axiosInstance.post(`form/create-division`, data);
  return response;
}

export async function updateCityListApi(data) {
  var response = await axiosInstance.put(`form/update-city`, data);
  return response;
}
export async function updateDivisonListApi(id, data) {
  var response = await axiosInstance.put(`form/update-division/${id}`, data);
  return response;
}
export async function getDivisonListByIdApi(id) {
  var response = await axiosInstance.post(`form/get-division-list/${id}`);
  return response;
}

export async function cityListAPI(stateid, page, limit) {
  var response = await axiosInstance.get(
    `form/get-city-list/${stateid}?page=${page}&limit=${limit}`
  );
  return response;
}

export async function tehsilListApi(id, page, limit) {
  var response = await axiosInstance.get(
    `form/get-tehsil-list/${id}?page=${page}&limit=${limit}`
  );
  return response;
}

export async function addTehsilsApi(data) {
  var response = await axiosInstance.post(`form/create-tehsil`, data);
  return response;
}

export async function updateTehsilsApi(data) {
  var response = await axiosInstance.put(`form/update-tehsil`, data);
  return response;
}

export async function deleteTehsilsApi(id) {
  var response = await axiosInstance.post(`form/delete-tehsil/${id}`);
  return response;
}

export async function getEducationsApi(page, limit) {
  var response = await axiosInstance.post(
    `form/get-educations?page=${page}&limit=${limit}`
  );
  return response;
}

export async function addEducationsApi(data) {
  var response = await axiosInstance.post(`form/create-education`, data);
  return response;
}

export async function deleteEducationsApi(data) {
  var response = await axiosInstance.post(`form/delete-education`, data);
  return response;
}

export async function updateEducationsApi(data) {
  var response = await axiosInstance.put(`form/update-education`, data);
  return response;
}

export async function getSubeducationsApi(page, limit, data) {
  var response = await axiosInstance.post(
    `form/get-sub-educations?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addSubeducationsApi(data) {
  var response = await axiosInstance.post(`form/create-sub-education`, data);
  return response;
}

export async function updateSubEducationsApi(data) {
  var response = await axiosInstance.put(`form/update-sub-education`, data);
  return response;
}

export async function deleteSubEducationsApi(data) {
  var response = await axiosInstance.post(`form/delete-sub-education`, data);
  return response;
}

export async function getsubsectsApi(page, limit, data) {
  var response = await axiosInstance.post(
    `form/get-sub-sect-list?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function getsectsApi(page, limit) {
  var response = await axiosInstance.get(
    `form/get-sect-list?page=${page}&limit=${limit}`
  );
  return response;
}

export async function addsubsectsApi(data) {
  var response = await axiosInstance.post(`form/create-sub-sect`, data);
  return response;
}
export async function addsectsApi(data) {
  var response = await axiosInstance.post(`form/create-sect`, data);
  return response;
}

export async function deletesubsectsApi(data) {
  var response = await axiosInstance.post(`form/delete-sub-sect`, data);
  return response;
}

export async function deletesectsApi(data) {
  var response = await axiosInstance.post(`form/delete-sub-sect/${data}`);
  return response;
}

export async function updatesubsectsApi(data) {
  var response = await axiosInstance.put(`form/update-sub-sect`, data);
  return response;
}

export async function updateSectStatusApi(data) {
  var response = await axiosInstance.put(
    `form/update-sect-status/${data?.id}`,
    data
  );
  return response;
}

export async function updateSectApi(id, data) {
  var response = await axiosInstance.post(`form/update-sect/${id}`, data);
  return response;
}

export async function getGotrasApi(page, limit, data) {
  var response = await axiosInstance.post(
    `form/get-gotra-list?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addGotrasApi(data) {
  var response = await axiosInstance.post(`form/create-gotra`, data);
  return response;
}

export async function deleteGotrasApi(data) {
  var response = await axiosInstance.post(`form/delete-gotra`, data);
  return response;
}

export async function deleteAllGotrasApi(data) {
  var response = await axiosInstance.post(`form/delete-gotras`, data);
  return response;
}

export async function updateGotrasApi(data) {
  var response = await axiosInstance.put(`form/update-gotra`, data);
  return response;
}

export async function getHobbiesApi(page, limit) {
  var response = await axiosInstance.post(
    `form/get-hobbies-list?page=${page}&limit=${limit}`
  );
  return response;
}

export async function addHobbiesApi(data) {
  var response = await axiosInstance.post(`form/create-hobbie`, data);
  return response;
}

export async function deleteHobbiesApi(data) {
  var response = await axiosInstance.post(`form/delete-hobbie`, data);
  return response;
}

export async function updateHobbiesApi(data) {
  var response = await axiosInstance.put(`form/update-hobbie`, data);
  return response;
}

export async function getMotherTonguesApi(page, limit) {
  var response = await axiosInstance.post(
    `form/get-mother-tangue-list?page=${page}&limit=${limit}`
  );
  return response;
}

export async function addMotherTonguesApi(data) {
  var response = await axiosInstance.post(`form/create-mother-tangue`, data);
  return response;
}

export async function deleteMotherTonguesApi(data) {
  var response = await axiosInstance.post(`form/delete-mother-tangue`, data);
  return response;
}

export async function updateMotherTonguesApi(data) {
  var response = await axiosInstance.put(`form/update-mother-tangue`, data);
  return response;
}

export async function getOccupationsApi(page, limit) {
  var response = await axiosInstance.post(
    `form/get-occupation-list?page=${page}&limit=${100}`
  );
  return response;
}

export async function addOccupationsApi(data) {
  var response = await axiosInstance.post(`form/create-occupation`, data);
  return response;
}

export async function deleteOccupationsApi(data) {
  var response = await axiosInstance.post(`form/delete-occupation`, data);
  return response;
}

export async function updateOccupationsApi(data) {
  var response = await axiosInstance.put(`form/update-occupation`, data);
  return response;
}

export async function getSuboccupationApi(page, limit, data) {
  var response = await axiosInstance.post(
    `form/get-sub-occupation-list?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addSuboccupationApi(data) {
  var response = await axiosInstance.post(`form/create-sub-occupation`, data);
  return response;
}

export async function deleteSuboccupationApi(data) {
  var response = await axiosInstance.post(`form/delete-sub-occupation`, data);
  return response;
}

export async function updateSuboccupationApi(data) {
  var response = await axiosInstance.put(`form/update-sub-occupation`, data);
  return response;
}

export async function getCollegespreferencesApi(page, limit) {
  var response = await axiosInstance.post(
    `form/get-collage-list?page=${page}&limit=${limit}`
  );
  return response;
}

export async function addCollegespreferencesApi(data) {
  var response = await axiosInstance.post(`form/create-collage`, data);
  return response;
}

export async function deleteCollegespreferencesApi(data) {
  var response = await axiosInstance.post(`form/delete-collage`, data);
  return response;
}

export async function updateCollegespreferencesApi(data) {
  var response = await axiosInstance.put(`form/update-collage`, data);
  return response;
}

export async function getRaashiApi(page, limit) {
  var response = await axiosInstance.post(
    `form/get-raashi-list?page=${page}&limit=${limit}`
  );
  return response;
}

export async function addRaashiApi(data) {
  var response = await axiosInstance.post(`form/create-raashi`, data);
  return response;
}

export async function deleteRaashiApi(data) {
  var response = await axiosInstance.post(`form/delete-raashi`, data);
  return response;
}

export async function updateRaashiApi(data) {
  var responce = await axiosInstance.put(`form/update-raashi`, data);
  return responce;
}

export async function getStarnakshatraApi(page, limit, data) {
  var responce = await axiosInstance.post(
    `form/get-star-nakshatra-list?page=${page}&limit=${limit}`,
    data
  );
  return responce;
}

export async function addStarnakshatraApi(data) {
  var responce = await axiosInstance.post(`form/create-star-nakshatra`, data);
  return responce;
}

export async function deleteStarnakshatraApi(data) {
  var response = await axiosInstance.post(`form/delete-star-nakshatra`, data);
  return response;
}

export async function updateStarnakshatraApi(data) {
  var responce = await axiosInstance.put(`form/update-star-nakshatra`, data);
  return responce;
}

export async function uploadExceldata(data) {
  var response = await axiosInstance.post(`excel/data-upload`, data);
  return response;
}

export async function uploadImportedData(data) {
  var response = await axiosInstance.post(
    `excel/all-section-bulk-user-create`,
    data
  );
  return response;
}

export async function otpByPassAPI(data) {
  var response = await axiosInstance.put(
    `master-admin/OTP-by-pass-update`,
    data
  );
  return response;
}

export async function otpByPassAPIStatus(data) {
  var response = await axiosInstance.get(`master-admin/OTP-by-pass-status`);
  return response;
}

export async function getPayPackgeApi() {
  var response = await axiosInstance.post(`master-admin/get-pacakge-list`);
  return response;
}
export async function getHinduPayPackgeApi() {
  var response = await axiosInstance.post(
    `master-admin/get-hindu-pacakge-list`
  );
  return response;
}

export async function uploadHinduBiodata(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/uploads-hindu-bio-data`,
    data
  );
  return response;
}

export async function getUploadsHinduBioDataApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/get-uploads-hindu-bio-data`,
    data
  );
  return response;
}

export async function taskAssignApi() {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/task-assign-hindu-biodata`
  );
  return response;
}

export async function taskAssignDetailsApi() {
  var responce = await axiosInstance.post(
    `master-admin/hindu-bio-data/single-task-details-hindu-biodata`
  );
  return responce;
}

export async function removeHinduBioDataCount() {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/count-remove-uploads-hindu-bio-data`
  );
  return response;
}

export async function userClickCount(data) {
  var response = await axiosInstance.post(
    `offline/create-user-click-count`,
    data
  );
  return response;
}

export async function getHinduEducationsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/get-form-detail`,
    data
  );
  return response;
}

export async function removeHinduBiodata(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/delete-uploads-hindu-bio-data`,
    data
  );
  return response;
}

export async function getHinduFormsAnalyticsApi() {
  var response = await axiosInstance.get(
    `master-admin/hindu-forms/get-hindu-form-counts`
  );
  return response;
}

export async function getHinduCountryListApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/get-country-list?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addHinduCountryListApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/create-country`,
    data
  );
  return response;
}

export async function deleteHinduCountryListApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/delete-country`,
    data
  );
  return response;
}

export async function updateHinduCountryListApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/update-country`,
    data
  );
  return response;
}

export async function getHinduStateListApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/get-state-list?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addHinduStateApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/create-state`,
    data
  );
  return response;
}

export async function updateHinduStateListApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/update-state`,
    data
  );
  return response;
}

export async function deleteHinduStateListApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/delete-state`,
    data
  );
  return response;
}

export async function getHinduDivisionListApi(id, page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/get-division-list/${id}?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addHinduDivisionApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/create-division`,
    data
  );
  return response;
}

export async function updateHinduDivisionListApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/update-division`,
    data
  );
  return response;
}

export async function deleteHinduDivisionListApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/delete-division`,
    data
  );
  return response;
}

export async function getHinduCityListApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/get-city-list?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addHinduCityApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/create-city`,
    data
  );
  return response;
}

export async function updateHinduCityListApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/update-city`,
    data
  );
  return response;
}

export async function deleteHinduCityListApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/delete-city`,
    data
  );
  return response;
}

export async function getHinduEducationsApiList(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/get-hindu-educations?${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addHinduEducationsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/create-hindu-educations`,
    data
  );
  return response;
}

export async function updateHinduEducationsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/update-hindu-educations`,
    data
  );
  return response;
}

export async function deleteHinduEducationsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/delete-hindu-educations`,
    data
  );
  return response;
}

export async function getHinduSubeducationsApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/get-hindu-sub-educations?${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addHinduSubeducationsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/create-hindu-sub-educations`,
    data
  );
  return response;
}

export async function updateHinduSubeducationsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/update-hindu-sub-educations`,
    data
  );
  return response;
}

export async function deleteHinduSubeducationsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/delete-hindu-sub-educations`,
    data
  );
  return response;
}

export async function getHinduTehsilsApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/get-tehsil-list?${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addHinduTehsilsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/create-tehsil`,
    data
  );
  return response;
}

export async function updateHinduTehsilsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/update-tehsil`,
    data
  );
  return response;
}

export async function deleteHinduTehsilsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/delete-tehsil`,
    data
  );
  return response;
}

export async function getHinduSectsApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/get-hindu-sect?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getHinduSectsByStateIdApi(id) {
  var response = await axiosInstance.get(
    `master-admin/hindu-forms/get-hindu-sect-by-state/${id}`
  );
  return response;
}
export async function getHinduSubSectsBySectIdApi(id) {
  var response = await axiosInstance.get(
    `master-admin/hindu-forms/get-hindu-sub-sect/${id}`
  );
  return response;
}

export async function addHinduSectsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/create-hindu-sect`,
    data
  );
  return response;
}

export async function updateHinduSectsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/update-hindu-sect`,
    data
  );
  return response;
}

export async function deleteHinduSectsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/delete-hindu-sect`,
    data
  );
  return response;
}

export async function getHinduSubSectsApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/get-hindu-sub-sect?${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addHinduSubSectsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/create-hindu-sub-sect`,
    data
  );
  return response;
}

export async function updateHinduSubSectsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/update-hindu-sub-sect`,
    data
  );
  return response;
}

export async function deleteHinduSubSectsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/delete-hindu-sub-sect`,
    data
  );
  return response;
}

export async function getHinduOccupationsApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/get-hindu-occupation?${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addHinduOccupationsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/create-hindu-occupation`,
    data
  );
  return response;
}

export async function updateHinduOccupationsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/update-hindu-occupation`,
    data
  );
  return response;
}

export async function deleteHinduOccupationsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/delete-hindu-occupation`,
    data
  );
  return response;
}

export async function getHinduSubOccupationsApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/get-hindu-sub-occupations?${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function addHinduSubOccupationsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/create-hindu-sub-occupations`,
    data
  );
  return response;
}

export async function deleteHinduSubOccupationsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/delete-hindu-sub-occupations`,
    data
  );
  return response;
}

export async function updateHinduSubOccupationsApi(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-forms/update-hindu-sub-occupations`,
    data
  );
  return response;
}

export async function getGlobalSearch(data) {
  var response = await axiosInstance.post(`global/search-user`, data);
  return response;
}

// export async function movetojain(data) {
//     var response = await axiosInstance.post(`master-admin/hindu-bio-data/save-to-jain-bio-data`, data)
export async function deleteAllCountryApi(data) {
  var response = await axiosInstance.post(`form/delete-countries`, data);
  return response;
}
export async function deleteAllStateApi(data) {
  var response = await axiosInstance.post(`form/delete-states`, data);
  return response;
}
export async function deleteAllCityApi(data) {
  var response = await axiosInstance.post(`form/delete-cities`, data);
  return response;
}
export async function deleteAllTehsilApi(data) {
  var response = await axiosInstance.post(`form/delete-tehsils`, data);
  return response;
}
export async function deleteAllSubEducationApi(data) {
  var response = await axiosInstance.post(`form/delete-sub-educations`, data);
  return response;
}
export async function deleteAllSubsectApi(data) {
  var response = await axiosInstance.post(`form/delete-sub-sects`, data);
  return response;
}
export async function moveToHindu(data) {
  var response = await axiosInstance.post(
    `master-admin/save-to-hindu-bio-data`,
    data
  );
  console.log("api-test", data);
  return response;
}
export async function moveToJain(data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/save-to-jain-bio-data`,
    data
  );
  return response;
}

export async function excelDownloadonlineUser(startId, endId, staffId) {
  const data = {
    startId: parseInt(startId),
    endId: parseInt(endId),
    staffId: staffId,
    type: "Online-User",
  };

  try {
    const response = await axiosInstance.post(
      "master-admin/get-online-users_excel",
      data,
      {
        responseType: "blob",
      }
    );

    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
export async function excelDownloadOfflineUser(
  startId = null,
  endId = null,
  staffId,
  selectedId = [],
  sub_type = null
) {
  let data = {
    staffId: staffId,
    type: "Offline-User",
    sub_type: sub_type,
  };
  if (startId) {
    data.startId = parseInt(startId);
  }
  if (endId) {
    data.endId = parseInt(endId);
  }
  if (selectedId?.length > 0) {
    data.user_id = selectedId;
  }

  try {
    const response = await axiosInstance.post(
      "offline/get-offline-users_excel",
      data,
      {
        responseType: "blob",
      }
    );

    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function excelDownloadBiodataUserApi(startId, endId, staffId) {
  const data = {
    startId: parseInt(startId),
    endId: parseInt(endId),
    staffId: staffId,
    type: "Biodata-User",
  };
  try {
    const response = await axiosInstance.post(
      "master-admin/get-biodata-users_excel",
      data,
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function excelDownloadWhatsAppResponceApi(
  startId,
  endId,
  staffId
) {
  const data = {
    startId: parseInt(startId),
    endId: parseInt(endId),
    staffId: staffId,
    type: "WhatsApp-Responce",
  };
  try {
    const response = await axiosInstance.post(
      "master-admin/get-whatsappresponce-users_excel",
      data,
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function getExcelHistoryApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-excel_history?${page}&limit=${limit}`,
    data
  );
  return response;
}

// export async function getOfflineExcelHistoryApi(page, limit, data) {
//   var response = await axiosInstance.get(
//     `offline/get-history-excel-download?order=DESC&page=${page}&limit=${limit}&section=offline`,
//     data
//   );
//   return response;
// }

export async function getOfflineExcelHistoryApi(page, limit, section, data) {
  const validSections = ["offline", "biodata"];
  const sectionParam = validSections.includes(section) ? section : "offline";

  var response = await axiosInstance.get(
    `offline/get-history-excel-download?order=DESC&page=${page}&limit=${limit}&section=${sectionParam}`,
    data
  );
  return response;
}

export async function DiffrentUserApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/diffrent-users?${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function BiodataUserApi(page, limit, data) {
  var response = await axiosInstance.post(
    `offline/all-users?${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function SuspendUserApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/suspend-users?${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function getUsersIdApi(data, limit, hasLimit = false) {
  var response = null;
  if (hasLimit) {
    response = await axiosInstance.post(
      `offline/all-users-for-excel-download?limit=${limit}`,
      data
    );
  } else {
    response = await axiosInstance.post(
      `offline/all-users-for-excel-download`,
      data
    );
  }
  return response;
}

export async function bioDataImageGetApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/biodata-image-get-data?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function HindubioDataImageGetApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-bio-data/hindu-biodata-image-get-data?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function moveToHinduFromOfflineUsers(data) {
  var response = await axiosInstance.post(
    `offline/save-to-hindu-bio-data`,
    data
  );
  return response;
}
export async function moveToShadiDoneFromOfflineUsers(data) {
  var response = await axiosInstance.post(
    `offline/save-to-shadi-done-data`,
    data
  );
  return response;
}

export async function moveOfflineToDiffrentLanguage(data) {
  var response = await axiosInstance.post(
    `offline/save-to-diffrent_language`,
    data
  );
  return response;
}

export async function moveOfflineToSuspended(data) {
  var response = await axiosInstance.post(
    `offline/save-to-suspend-data-from-offline`,
    data
  );
  return response;
}
export async function moveOfflineToOtherCast(data) {
  var response = await axiosInstance.post(
    `offline/save-to-other-cast-data-from-offline`,
    data
  );
  return response;
}

export async function moveToHinduFromBioDataUsers(data) {
  var response = await axiosInstance.post(
    `master-admin/save-to-hindu-bio-data-from-biodata`,
    data
  );
  return response;
}
export async function moveToDiffrentFromBioDataUsers(data) {
  var response = await axiosInstance.post(
    `master-admin/save-to-diffrent-language-biodata`,
    data
  );
  return response;
}
export async function moveToSuspendFromBioDataUsers(data) {
  var response = await axiosInstance.post(
    `master-admin/save-to-suspend-data`,
    data
  );
  return response;
}
export async function moveToShadiDoneFromBioDataUsers(data) {
  var response = await axiosInstance.post(
    `master-admin/save-to-shadi-done-data`,
    data
  );
  return response;
}
export async function moveToOutOfIndiaFromBioDataUsers(data) {
  var response = await axiosInstance.post(
    `master-admin/save-to-out-of-india-user`,
    data
  );
  return response;
}

export async function updateSliderView(data) {
  var response = await axiosInstance.post(
    `master-admin/update-packages-slider_view-button`,
    data
  );
  return response;
}

export async function updateSlider_OrderHindu(data) {
  var response = await axiosInstance.post(
    `master-admin/update-hindu-package`,
    data
  );
  return response;
}
export async function updateSliderOrder(data) {
  var response = await axiosInstance.post(
    `master-admin/update-packages-slider_order`,
    data
  );
  return response;
}
export async function getContact() {
  var response = await axiosInstance.post(`master-admin/conatcts`);
  return response;
}
export async function getHinduContact() {
  var response = await axiosInstance.post(`master-admin/hindu-conatcts`);
  return response;
}
export async function getNumbers() {
  var response = await axiosInstance.get(
    `master-admin/get-mobilenumbers-from-accessories`
  );
  return response;
}
export async function updateContact(id, data) {
  var response = await axiosInstance.post(
    `master-admin/update-conatcts/${id}`,
    data
  );
  return response;
}
export async function updateHinduContact(id, data) {
  var response = await axiosInstance.post(
    `master-admin/update-hindu-conatcts/${id}`,
    data
  );
  return response;
}
export async function uploadBiodataImages(data) {
  var response = await axiosInstance.post(
    `master-admin/uploads-bio-data-new`,
    data
  );
  return response;
}
export async function createAutoOffer(data) {
  var response = await axiosInstance.post(
    `master-admin/create-auto-packages`,
    data
  );
  return response;
}
export async function getAutoOffer() {
  var response = await axiosInstance.get(`master-admin/fetch-auto-packages`);
  return response;
}
export async function getBasicPkgListAuto() {
  var response = await axiosInstance.get(`master-admin/fetch-basic-packages`);
  return response;
}
export async function autoOfferStatus(id, data) {
  var response = await axiosInstance.put(
    `master-admin/update-auto-packages-status-button/${id}`,
    data
  );
  return response;
}
export async function updateAutoOffer(id, data) {
  var response = await axiosInstance.put(
    `master-admin/update-auto-packages/${id}`,
    data
  );
  return response;
}
export async function deleteAutoOffer(id) {
  var response = await axiosInstance.delete(
    `master-admin/delete-auto-packages/${id}`
  );
  return response;
}
export async function downloadExcelById(data) {
  var response = await axiosInstance.post(
    `master-admin/get-online-users_excel-by-id`,
    data
  );
  return response;
}
export async function getImageHashData() {
  var response = await axiosInstance.post(`master-admin/image-hash`);
  return response;
}
export async function fetchImageHashData(page, limit) {
  // var response = await axiosInstance.post(`master-admin/fetch-image-hash?page=${page}&limit=${limit}`);
  var response = await axiosInstance.post(
    `master-admin/get-image-hash?page=${page}&limit=${limit}`
  );
  return response;
}
export async function deleteScannerImage(data) {
  var response = await axiosInstance.post(
    `master-admin/delete-bio-data-scanner-image`,
    data
  );
  return response;
}
export async function getShadiDoneData(page, limit, signal) {
  var response = await axiosInstance.post(
    `get-shadi-done-table?page=${page}&limit=${limit}`,
    {},
    { signal }
  );
  return response;
}
export async function getHinduShadiDoneData(page, limit) {
  var response = await axiosInstance.post(
    `hindu_app/get-shadi-done-table?page=${page}&limit=${limit}`
  );
  return response;
}
export async function uploadWhatsappResponseExcel(data) {
  var response = await axiosInstance.post(`excel/whatsapp-excel-upload`, data);
  return response;
}
export async function uploadGroupServiceExcel(data) {
  var response = await axiosInstance.post(
    `excel/group-service-excel-upload`,
    data
  );
  return response;
}
export async function uploadPaidPromotionExcel(data) {
  var response = await axiosInstance.post(
    `excel/paid-promotion-excel-upload`,
    data
  );
  return response;
}
export async function uploadSocialMediaExcel(data) {
  var response = await axiosInstance.post(
    `excel/social-media-excel-upload`,
    data
  );
  return response;
}
export async function uploadHinduWhatsappResponseExcel(data) {
  var response = await axiosInstance.post(
    `excel/hindu-whatsapp-excel-upload`,
    data
  );
  return response;
}
export async function uploadHinduGroupServiceExcel(data) {
  var response = await axiosInstance.post(
    `excel/Hindu-group-service-excel-upload`,
    data
  );
  return response;
}
export async function uploadHinduPaidPromotionExcel(data) {
  var response = await axiosInstance.post(
    `excel/hindu-paid-promotion-excel-upload`,
    data
  );
  return response;
}
export async function uploadHinduSocialMediaExcel(data) {
  var response = await axiosInstance.post(
    `excel/hindu-social-media-excel-upload`,
    data
  );
  return response;
}
export async function createWhatsappResponse(data) {
  var response = await axiosInstance.post(
    `master-admin/create-whatsapp-response`,
    data
  );
  return response;
}
// Start response data import api
export async function createWhatsappResponseExcel(data) {
  var response = await axiosInstance.post(
    `master-admin/create-whatsapp-responce-newOne`,
    data
  );
  return response;
}
export async function createGroupServicesExcel(data) {
  var response = await axiosInstance.post(
    `master-admin/create-whatsapp-group-response-new`,
    data
  );
  return response;
}
export async function createPaidPRomotionExcel(data) {
  var response = await axiosInstance.post(
    `master-admin/create-paid-promotion-new`,
    data
  );
  return response;
}
export async function createSocialMediaExcel(data) {
  var response = await axiosInstance.post(
    `master-admin/create-social-media-new`,
    data
  );
  return response;
}
export async function createHinduWhatsappResponseExcel(data) {
  var response = await axiosInstance.post(
    `master-admin/create-hindu-whatsapp-responce-newOne`,
    data
  );
  return response;
}
export async function createHinduGroupServicesExcel(data) {
  var response = await axiosInstance.post(
    `master-admin/create-hindu-whatsapp-group-response-new`,
    data
  );
  return response;
}
export async function createHinduPaidPRomotionExcel(data) {
  var response = await axiosInstance.post(
    `master-admin/create-hindu-social-media-new`,
    data
  );
  return response;
}
export async function createHinduSocialMediaExcel(data) {
  var response = await axiosInstance.post(
    `master-admin/create-hindu-paid-promotion-new`,
    data
  );
  return response;
}
// Hindu
// export async function createHinduWhatsappResponseExcel(data) {
//   var response = await axiosInstance.post(
//     `master-admin/create-whatsapp-responce-newOne`,
//     data
//   );
//   return response;
// }
// export async function createHinduGroupServicesExcel(data) {
//   var response = await axiosInstance.post(
//     `master-admin/create-whatsapp-group-response-new`,
//     data
//   );
//   return response;
// }
// export async function createHinduPaidPRomotionExcel(data) {
//   var response = await axiosInstance.post(
//     `master-admin/create-paid-promotion-new`,
//     data
//   );
//   return response;
// }
// export async function createHinduSocialMediaExcel(data) {
//   var response = await axiosInstance.post(
//     `master-admin/create-social-media-new`,
//     data
//   );
//   return response;
// }
// end response data import api

export async function createGroupService(data) {
  var response = await axiosInstance.post(
    `master-admin/create-whatsapp-group-response`,
    data
  );
  return response;
}
export async function createPaidPromotion(data) {
  var response = await axiosInstance.post(
    `master-admin/create-paid-promotion`,
    data
  );
  return response;
}
export async function createSocialMedia(data) {
  var response = await axiosInstance.post(
    `master-admin/create-social-media`,
    data
  );
  return response;
}
export async function removeFromShadiDoneRequestApi(data) {
  var response = await axiosInstance.post(
    `get-shadi-done-table-data-delete`,
    data
  );
  return response;
}
export async function addBannerApi(data) {
  var response = await axiosInstance.post(`master-admin/add-banner`, data);
  return response;
}
export async function addHinduBannerApi(data) {
  var response = await axiosInstance.post(
    `master-admin/add-hindu-banner`,
    data
  );
  return response;
}
export async function deleteBannerApi(id) {
  var response = await axiosInstance.post(`master-admin/delete-banner/${id}`);
  return response;
}
export async function deleteHinduBannerApi(id) {
  var response = await axiosInstance.post(
    `master-admin/delete-hindu-banner/${id}`
  );
  return response;
}
export async function updateBannerApi(id, data) {
  var response = await axiosInstance.post(
    `master-admin/update-banner/${id}`,
    data
  );
  return response;
}
export async function updateHinduBannerApi(id, data) {
  var response = await axiosInstance.post(
    `master-admin/update-hindu-banner/${id}`,
    data
  );
  return response;
}
export async function updateBannerStatusApi(id, data) {
  var response = await axiosInstance.post(
    `master-admin/banner-status-button/${id}`,
    data
  );
  return response;
}
export async function updateHinduBannerStatusApi(id, data) {
  var response = await axiosInstance.post(
    `master-admin/hindu-banner-status-button/${id}`,
    data
  );
  return response;
}
export async function createMessageHeadingApi(data) {
  var response = await axiosInstance.post(`master-admin/create-heading`, data);
  return response;
}
// export async function updateMessageHeadingApi(data) {
//   var response = await axiosInstance.post(`master-admin/update-heading-update`, data);
//   return response;
// }
export async function createLanguage(data) {
  var response = await axiosInstance.post(
    `master-admin/create-language-new`,
    data
  );
  return response;
}
export async function updateLanguage(id, data) {
  var response = await axiosInstance.put(
    `master-admin/update-language/${id}`,
    data
  );
  return response;
}

export async function getMessageLanguage() {
  var response = await axiosInstance.post(`master-admin/get-language`);
  return response;
}
export async function createMessage(id, data) {
  var response = await axiosInstance.put(
    `master-admin/update-message-different-lang/${id}`,
    data
  );
  return response;
}
export async function updateMessage(id, data) {
  var response = await axiosInstance.put(
    `master-admin/update-message-modified-different-lang/${id}`,
    data
  );
  return response;
}

export async function deleteMessageHeadingApi(id) {
  var response = await axiosInstance.post(`master-admin/delete-heading/${id}`);
  return response;
}
export async function getMessageHeadingApi(data) {
  var response = await axiosInstance.post(`master-admin/fetch-heading`, data);
  return response;
}
export async function getPendingOfflineUsersList(page, limit) {
  var response = await axiosInstance.post(
    `master-admin/get-list-offline?page=${page}&limit=${limit}`
  );
  return response;
}
// export async function getPendingOnlineUsersList() {
//   var response = await axiosInstance.post(`master-admin/get-list-online`);
//   return response;
// }
export async function getPendingOnlineUsersListFiltered(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-list-online-verify-process?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getSameDataApi(page, limit, signal) {
  var response = await axiosInstance.post(
    `offline/same-mobile-users?page=${page}&limit=${limit}`,
    {},
    { signal }
  );
  return response;
}

export async function updateMobileStatusCloud(data) {
  var response = await axiosInstance.post(
    `staff/update-cloud-mobile-status`,
    data
  );
  return response;
}

export async function groupLinkCount() {
  var response = await axiosInstance.get(`group-link/get-master-count`);
  return response;
}
export async function groupLinkData(page, limit, data) {
  var response = await axiosInstance.post(
    `group-link/get-master-count-list?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function updateGroupLink(id, data) {
  var response = await axiosInstance.post(`group-link/update/${id}`, data);
  return response;
}
export async function getBiodataListApi(page, limit) {
  var response = await axiosInstance.post(
    `master-admin/get-list-biodata?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getGroupServiceListApi(page, limit) {
  var response = await axiosInstance.post(
    `master-admin/get-list-group-service?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getIncomingServiceListApi(page, limit) {
  var response = await axiosInstance.post(
    `master-admin/get-list-incoming-call?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getPaidPromotionListApi(page, limit) {
  var response = await axiosInstance.post(
    `master-admin/get-list-paid-promotion?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getSocialMediaListApi(page, limit) {
  var response = await axiosInstance.post(
    `master-admin/get-list-social-media?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getWhatsappResponseListApi(page, limit) {
  var response = await axiosInstance.post(
    `master-admin/get-list-whats-app-respoce?page=${page}&limit=${limit}`
  );
  return response;
}
export async function sendForceNotificationApi(data) {
  var response = await axiosInstance.post(`push-notification-all-users`, data);
  return response;
}

export async function getGoliveFormDetails() {
  var response = await axiosInstance.get(`hindu-form-detail/go-live`);
  return response;
}

export async function getRemoveSameImg() {
  var response = await axiosInstance.get(`master-admin/same-image-remove`);
  return response;
}
export async function startScannerApi() {
  var response = await axiosInstance.get(`master-admin/start-scanner`);
  return response;
}
export async function getScannerImageNameApi() {
  var response = await axiosInstance.get(
    `master-admin/get-scanner-image-name-api`
  );
  return response;
}
export async function getScannerImageHinduNameApi() {
  var response = await axiosInstance.get(
    `master-admin/get-scanner-image-name-hindu-api`
  );
  return response;
}
export async function updateScannerImageStatusApi(id) {
  var response = await axiosInstance.get(
    `master-admin/update-scanner-image-status/${id}`
  );
  return response;
}
export async function acceseroisHistoryId(id) {
  var response = await axiosInstance.get(
    `master-admin/get-staff-accessories-history/${id}`
  );
  return response;
}
export async function updateImageStatusApi(id) {
  var response = await axiosInstance.get(
    `master-admin/update-biodata-image-data-status/${id}`
  );
  return response;
}
export async function deleteScannerImageStatusApi(id) {
  var response = await axiosInstance.get(
    `master-admin/delete-scanner-image-status/${id}`
  );
  return response;
}
export async function updateScannerImageStatusHinduApi(id) {
  var response = await axiosInstance.get(
    `master-admin/update-scanner-image-status-hindu/${id}`
  );
  return response;
}
export async function deleteScannerImageStatusHinduApi(id) {
  var response = await axiosInstance.get(
    `master-admin/delete-scanner-image-status-hindu/${id}`
  );
  return response;
}
export async function logoutUser(data) {
  var response = await axiosInstance.post("auth/logout-status", data);
  return response;
}
export async function createAccessForStaff(data) {
  var response = await axiosInstance.post(
    "master-admin/roles/staff-app/access/create",
    data
  );
  return response;
}
export async function callTeamAssignApi(data) {
  var response = await axiosInstance.post("staff/create-access", data);
  return response;
}

export async function callTeamStatusUpdateApi(data) {
  var response = await axiosInstance.post("staff/update-access", data);
  return response;
}
export async function createDoubleTickKeys(data) {
  var response = await axiosInstance.post(
    "master-admin/roles/staff/double-tick/access/create",
    data
  );
  return response;
}
export async function getDoubleTickStaffApi() {
  var response = await axiosInstance.post(
    "master-admin/roles/staff/double-tick/access/get"
  );
  return response;
}
export async function updatedoubleTickAccess(data) {
  var response = await axiosInstance.post(
    "master-admin/roles/staff/double-tick/access/update",
    data
  );
  return response;
}
export async function updateAccessForStaff(data) {
  var response = await axiosInstance.put(
    "master-admin/roles/staff-app/access/update",
    data
  );
  return response;
}
export async function addScannerDataApi(data) {
  var response = await axiosInstance.post(
    "master-admin/add-bulk-image-data",
    data
  );
  return response;
}
export async function addScannerHinduDataApi(data) {
  var response = await axiosInstance.post(
    "master-admin/add-hindu-bulk-image-data",
    data
  );
  return response;
}
export async function getScannerDataApi(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-image-data?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function createPhonepePaymentLink(data) {
  var response = await axiosInstance.post(
    "master-admin/create-phonepe-link",
    data
  );
  return response;
}
export async function createPhonepePaymentLinkHindu(data) {
  var response = await axiosInstance.post(
    "master-admin/create-phonepe-link-hindu",
    data
  );
  return response;
}
export async function getSuspendedUserOfflineList(page, limit) {
  var response = await axiosInstance.post(
    `offline/get-suspend-data?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getHinduUserOfflineList(page, limit) {
  var response = await axiosInstance.post(
    `offline/get-hindu-offline-data?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getOtherUserOfflineList(page, limit) {
  var response = await axiosInstance.post(
    `offline/get-other-cast-data?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getShadiDoneUserOfflineList(page, limit) {
  var response = await axiosInstance.post(
    `offline/get-to-shadi-done-data?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getDiffrentUserOfflineList(page, limit) {
  var response = await axiosInstance.post(
    `offline/get-diffrent-language-data?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getSuspendedUserBiodataList(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-suspend-data?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getHinduUserBiodataList(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-hindu-bio-data?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getDiffrentUserBiodataList(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-diffrent-language-data?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getShadiDoneUserBiodataList(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-to-shadi-done-data?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getScannerNullImageBiodataList(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-null-image-data?page=${page}&limit=${limit}`,
    data
  );
  return response;
}
export async function getScannerNullMobileUserBiodataList(page, limit, data) {
  var response = await axiosInstance.post(
    `master-admin/get-null-mobile-image-data?page=${page}&limit=${limit}`,
    data
  );
  return response;
}

export async function moveToShadiWhatsappResponse(data) {
  var response = await axiosInstance.post(
    `master-admin/save-to-wapp-res-shadi-done-data`,
    data
  );
  return response;
}
export async function runScannerApi() {
  var response = await axiosInstance.post(
    `master-admin/create-data-image-data`
  );
  return response;
}
export async function moveToShadiGroupService(data) {
  var response = await axiosInstance.post(
    `master-admin/save-to-grp-ser-shadi-done-data`,
    data
  );
  return response;
}
export async function moveToShadiSocialMedia(data) {
  var response = await axiosInstance.post(
    `master-admin/save-to-social-media-shadi-done-data`,
    data
  );
  return response;
}
export async function moveToShadiPaidPropmotion(data) {
  var response = await axiosInstance.post(
    `master-admin/save-to-paid-promotion-shadi-done-data`,
    data
  );
  return response;
}
export async function getShadiPaidPropmotion(page, limit) {
  var response = await axiosInstance.post(
    `master-admin/get-paid-promotion-shadi-done-data?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getShadiSocialMedia(page, limit) {
  var response = await axiosInstance.post(
    `master-admin/get-social-media-shadi-done-data?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getShadiWhatsappResponse(page, limit) {
  var response = await axiosInstance.post(
    `master-admin/get-wapp-res-shadi-done-data?page=${page}&limit=${limit}`
  );
  return response;
}
export async function getShadiGroupService(page, limit) {
  var response = await axiosInstance.post(
    `master-admin/get-grp-ser-shadi-done-data?page=${page}&limit=${limit}`
  );
  return response;
}
export async function extractInfoFromGpt(data) {
  const prompt = `
  Extract and structure biodata from the following unstructured text.
  Please provide the results in a single JSON array format, where each object in the array corresponds to a record, including the following fields:
  id, name, mobile, dob, occupation, gender (detect if possible from name), education, marital_status, physical_status, sect, state, city, tehsil, address, employed_in, caste_religion, height, family_status_income, mother_tongue, special_college, eating_habits, manglik, country, gmail_id, sub_sect. income_of_user.
  If some fields are not available, leave them empty.
  Here is the text array:
 
  Return only the structured JSON array containing the fields and the id.
  `;

  var response = await gpt_instance.post(`/chat/completions`, {
    model: "gpt-3.5-turbo",
    assistant_id: "asst_Zf5dH752vdbxn9kusVdS9opv",
    messages: [{ role: "user", content: `${data}` }],
  });
  return response;
}

export const scannerUploadingImagesApi = async (data) => {
  var response = await scannerInstance.post(`jsm-upload-images`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};
export const createUniversalPaymentLinkApi = async (data) => {
  var response = await axiosInstance.post(
    `payment/create-universal-payment-link`,
    data
  );
  return response;
};
export const scannerScanImagesApi = async () => {
  var response = await scannerInstance.get(`jsm-scan-images`);
  return response;
};
export const scannerScanImagesByGptApi = async () => {
  var response = await scannerInstance.get(`jsm-normalize-data`);
  return response;
};

export const updateAccessoriesStatus = async (id) => {
  var response = await axiosInstance.get(
    `master-admin/update-accessories-status/${id}`
  );
  return response;
};
export const removeDuplicateApiPy = async (type, query) => {
  let response = await axiosInstanceScanner.post(
    `generate-upload-report/${type}`,
    query
  );
  return response;
};
// export const getScannerDataApiPy = async (query) => {
//   let response = await axiosInstanceScanner.post(`get-images/jain`, query);
//   return response;
// };
// export const getScannerHinduDataApiPy = async (query) => {
//   let response = await axiosInstanceScanner.post(`get-images/hindu`, query);
//   return response;
// };
export const getScannerDataApiPy = async (query) => {
  let response = await axiosInstanceNodeScanner.post(
    `get-filter-images/jain?page=${query.page}&limit=${query.limit}`,
    query
  );
  return response;
};
export const getScannerHinduDataApiPy = async (query) => {
  let response = await axiosInstanceNodeScanner.post(
    `get-filter-images/hindu?page=${query.page}&limit=${query.limit}`,
    query
  );
  return response;
};
export const uploadScannerDataApiPy = async (data) => {
  var response = await axiosInstanceScanner.post(`upload-images/jain`, data);
  return response;
};
export const uploadScannerDataHinduApiPy = async (data) => {
  var response = await axiosInstanceScanner.post(`upload-images/hindu`, data);
  return response;
};

export const scanBiodataPy = async (data) => {
  var response = await axiosInstanceScanner.post(`scan-images/jain`, data);
  return response;
};
export const gptBiodataPy = async (data) => {
  var response = await axiosInstanceScanner.post(
    `normalize-data-by-gpt/jain`,
    data
  );
  return response;
};

//assign task to work from home staff

// export const finalBiodataPy = async (data) => {
//   var response = await axiosInstanceScanner.post(`final-update/jain`, data);
//   return response;
// };
// export const finalBiodataHinduPy = async (data) => {
//   var response = await axiosInstanceScanner.post(`final-update/hindu`, data);
//   return response;
// };
export const finalBiodataHinduPy = async (data) => {
  var response = await axiosInstanceNodeScanner.post(
    `final-update/hindu`,
    data
  );
  return response;
};
export const finalBiodataPy = async (data) => {
  var response = await axiosInstanceNodeScanner.post(`final-update/jain`, data);
  return response;
};
export const deleteBiodataPy = async (data) => {
  var response = await axiosInstanceScanner.post(`delete-images/jain`, data);
  return response;
};

export const scanBiodataHinduPy = async (data) => {
  var response = await axiosInstanceScanner.post(`scan-images/hindu`, data);
  return response;
};
export const gptBiodataHinduPy = async (data) => {
  var response = await axiosInstanceScanner.post(
    `normalize-data-by-gpt/hindu`,
    data
  );
  return response;
};
// export const updateMobileBiodataPy = async (type, data) => {
//   var response = await axiosInstanceScanner.post(
//     `update-mobile-from-description/${type}`,
//     data
//   );
//   return response;
// };
export const getAssignScannerData = async (type, data) => {
  var response = await axiosInstanceNodeScanner.post(
    `get-all-task/${type}`,
    data
  );
  return response;
};
export const selfAssignScannerData = async (type, data) => {
  var response = await axiosInstanceNodeScanner.post(
    `assign-data/${type}`,
    data
  );
  return response;
};
export const loadWFHData = async (type, data) => {
  var response = await axiosInstanceNodeScanner.post(`load-task/${type}`, data);
  return response;
};
export const moveToData = async (type, data) => {
  var response = await axiosInstanceNodeScanner.post(
    `move-to-hindu-or-jain/${type}`,
    data
  );
  return response;
};
export const staffAssignTaskReportData = async (data) => {
  var response = await axiosInstanceNodeScanner.post(`get-staff-report`, data);
  return response;
};
export const approveTaskStatus = async (type, id, data) => {
  var response = await axiosInstanceNodeScanner.post(
    `/approve-status/${type}/${id}`,
    data
  );
  return response;
};
export const updateShadiDoneStatus = async (type, data) => {
  var response = await axiosInstanceNodeScanner.put(
    `update-shadi-done-status/${type}`,
    data
  );
  return response;
};
export const copyBioDataCountHistory = async (type, data) => {
  var response = await axiosInstanceNodeScanner.post(
    `data-copy/${type}`,
    data
  );
  return response;
};
export const callBioData = async (type, data) => {
  var response = await axiosInstanceNodeScanner.post(
    `call/${type}`,
    data
  );
  return response;
};
export const copyBioDataCountHistoryReport = async (type) => {
  var response = await axiosInstanceNodeScanner.get(
    `get-data-copy-report/${type}`);
  return response;
};
export const updateMobileBiodataPy = async (type, data) => {
  var response = await axiosInstanceNodeScanner.post(
    `update-mobile-from-description/${type}`,
    data
  );
  return response;
};
export const deleteBiodataHinduPy = async (data) => {
  var response = await axiosInstanceScanner.post(`delete-images/hindu`, data);
  return response;
};
// export const viewBiodataByIdPy = async (data) => {
//   var response = await axiosInstanceScanner.get(`get-image/jain/${data}`);
//   return response;
// };

// export const viewBiodataByIdPyHindu = async (data) => {
//   var response = await axiosInstanceScanner.get(`get-image/hindu/${data}`);
//   return response;
// };
export const viewBiodataByIdPy = async (data) => {
  var response = await axiosInstanceNodeScanner.get(
    `get-image-by-id/jain/${data}`
  );
  return response;
};

export const viewBiodataByIdPyHindu = async (data) => {
  var response = await axiosInstanceNodeScanner.get(
    `get-image-by-id/hindu/${data}`
  );
  return response;
};
// export const updateScannerForm = async (data, id, type) => {
//   var response = await axiosInstanceScanner.post(
//     `update-data-by-staff/${type}/${id}`,
//     data
//   );
//   return response;
// };
export const updateScannerForm = async (data, type) => {
  var response = await axiosInstanceNodeScanner.put(
    `update-data-by-staff/${type}`,
    data
  );
  return response;
};
export const removeScannerTask = async (data, type) => {
  var response = await axiosInstanceNodeScanner.post(
    `remove-task/${type}`,
    data
  );
  return response;
};
export const mobileCheckPy = async (data) => {
  var response = await axiosInstanceScanner.get(
    `duplicate-mobile-checker/${data}`
  );
  return response;
};
export const addOrganizationPy = async (data) => {
  var response = await axiosInstanceScanner.post(
    `add-update-matrimonial-number`,
    data
  );
  return response;
};
export const fetchPaymentErrorById = async (id) => {
  var response = await axiosInstance.get(`payment/payment-error-details/${id}`);
  return response;
};
export const VerifyPaymentById = async (id) => {
  var response = await axiosInstance.get(
    `payment/payment-details-verification/${id}`
  );
  return response;
};

export default axiosInstance;
