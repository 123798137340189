import React, { useCallback, useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet-async";
import {
  Stack,
  Grid,
  Box,
  TextField,
  Chip,
  Button,
  Autocomplete,
  CardContent,
  Card,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Iconify from "../../components/iconify";
import FormProvider, {
  RHFTextField,
  RHFAutocomplete,
} from "../../components/hook-form";
import {
  getDetailList,
  getCountryListAPI,
  subSectApi,
  getStateListAPI,
  updateBiodata,
  bioDataDetail,
  updateBiodataCount,
  removeBiodataCount,
} from "src/service";
import { useSnackbar } from "../../components/snackbar";
import Image from "src/components/image/Image";
import constant from "src/Constant";
import blankUSer from "../../assets/images/blank-profile-picture-973460__340.webp";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { PATH_DASHBOARD } from "../../routes/paths";
import { useNavigate } from "react-router";
import { AuthContext } from "src/auth/JwtContext";
import { useAuthContext } from "src/auth/useAuthContext";

import Scrollbar from "src/components/scrollbar/Scrollbar";

// ----------------------------------------------------------------------
const disabilitys = [
  { id: 1, name: "Normal" },
  { id: 2, name: "Some Medical Problem" },
  { id: 3, name: "Physically Challenged" },
];
const maritalStatus = [
  { id: 1, name: "Unmarried" },
  { id: 2, name: "Widow/widower" },
  { id: 3, name: "Divorce" },
  { id: 4, name: "Awiting divorce" },
];

const manlik = [
  { id: 1, name: "YES" },
  { id: 2, name: "NO" },
  { id: 3, name: "DON`T KNOW" },
  { id: 4, name: "Anshik" },
];

export default function BioDataUpdate({}) {
  const [countryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [subsect, setSubsect] = useState([]);
  const [Detail, setDetail] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [arr, setArr] = useState([{ number: "" }]);
  const [year, setYear] = useState([]);
  const [item, setItem] = useState({ item: "" });
  const [filterMob, setFilter] = useState();
  const [bioImage, setImage] = useState([]);
  const [images, setImages] = useState(null);
  const [education, setEducation] = useState([]);
  const [newRow, setNewRow] = useState("");
  const [showUpdate, setUpdate] = useState({});
  // new
  const navigate = useNavigate();
  const AppContext = useContext(AuthContext);
  const [showMobileError, setShowMobileError] = useState(false);
  const [biodataCount, setBiodataCount] = useState({});
  const [totalRemove, setTotalRemove] = useState("");
  const [gender, setGender] = useState([]);
  const [name, setName] = useState([]);
  const [employed, setEmployed] = useState([]);
  const [sect, setSect] = useState([]);
  const [subSect, setSubSect] = useState([]);
  const [manglik, setManglik] = useState([]);
  const [state, setState] = useState([]);
  const [marital, setMarital] = useState([]);
  const [disability, setDisability] = useState([]);
  const [dob, setDob] = useState([]);
  const contxt = useAuthContext();

  console.log("AUTH:", contxt?.user?.id);

  useEffect(() => {
    getCountryList();
    getDetail();
    birthYear();
    getBiodataCount();
    getRemoveCount();
  }, []);

  useEffect(() => {
    getBiodataFilterDetail();
  }, [filterMob]);

  useEffect(() => {
    if (showUpdate?.name) setName(showUpdate.name);
    if (showUpdate?.gender) setGender(showUpdate?.gender);
    if (showUpdate?.employed_in) setEmployed(showUpdate?.employed_in);
    if (showUpdate?.education) {
      setValue("education", showUpdate?.education.split(","));
      setEducation(showUpdate?.education.split(","));
    }
    if (showUpdate?.sect) setSect(showUpdate?.sect);
    if (showUpdate?.sub_sect) setSubSect(showUpdate?.sub_sect);
    if (showUpdate?.manglik) setManglik(showUpdate?.manglik);
    if (showUpdate?.state) setState(showUpdate?.state);
    if (showUpdate?.country) setValue("country", showUpdate?.country);
    if (showUpdate?.marital_status) setMarital(showUpdate?.marital_status);
    if (showUpdate?.physical_status) setDisability(showUpdate?.physical_status);
    if (showUpdate?.dob) setDob(showUpdate?.dob);
    if (showUpdate?.image)
      setImages(
        `${constant.appBaseUrl}/users/offline_users/${showUpdate?.image}`
      );
  }, [showUpdate]);

  useEffect(() => {
    setArr(
      item?.item?.mobile
        ? item?.item?.mobile?.split(",").map((item) => {
            return { number: item };
          })
        : []
    );
  }, [item?.item?.mobile]);

  const getBiodataFilterDetail = async () => {
    var obj = {};
    if (filterMob) obj.mobile = filterMob;
    let res = await bioDataDetail(obj);
    console.log("swapnil", res);
  };

  const getBiodataCount = async (data) => {
    let res = await updateBiodataCount(data);
    setBiodataCount(res?.data?.data);
  };

  const getRemoveCount = async () => {
    let res = await removeBiodataCount();
    setTotalRemove(res?.data?.data?.totalRemoveImage);
  };

  useEffect(() => {
    const filter =
      window.location.pathname.split("/")[
        window.location.pathname?.split("/")?.length - 1
      ];
    getBiodataDetail({ startId: filter, endId: filter });
  }, []);

  const getBiodataDetail = async (filter) => {
    let res = await bioDataDetail({}, {}, filter);
    setItem({ item: res?.data?.data?.users[0] });
    setNewRow(res?.data?.data?.users[0]);
    setUpdate(res?.data?.data?.users[0]);
  };

  const birthYear = () => {
    let currentYear = new Date().getFullYear();
    let range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    setYear(range(currentYear - 18, currentYear - 79, -1));
  };

  const addInput = () => {
    setArr([...arr, { number: "" }]);
  };

  const handleRemoveItem = (index) => {
    const list = [...arr];
    list.splice(index, 1);
    setArr(list);
  };

  const addNumber = (ind, val) => {
    const newArray = arr.map((item, i) => {
      if (ind === i) {
        return { ...item, number: val };
      } else {
        return item;
      }
    });
    setArr(newArray);
  };

  const defaultValues = {
    mobile: item?.item?.mobile,
    name: item?.item?.name,
    gender: item?.item?.gender,
    employed_in: item?.item?.employed_in,
    education: item?.item?.education,
    sect: item?.item?.sect,
    sub_sect: item?.item?.sub_sect,
    manglik: item?.item?.manglik,
    country: item?.item?.country,
    state: item?.item?.state,
    marital_status: item?.item?.marital_status,
    physical_status: item?.item?.physical_status,
    dob: item?.item?.dob,
  };

  const LoginSchema = Yup.object().shape({});

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    getValues,
    setError,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;

  const getCountryList = async () => {
    let res = await getCountryListAPI();
    setCountryList(res?.data?.data);
  };

  const getDetail = async () => {
    let res = await getDetailList();
    setDetail(res?.data?.data);
  };

  const watchCountry = watch("country");
  useEffect(() => {
    let v = getValues("country");
    getStateList(v || "India");
  }, [watchCountry, getValues]);

  const getStateList = async (value) => {
    let res = await getStateListAPI(value);
    setStateList(res?.data?.data);
  };

  useEffect(() => {
    selectSubSect(sect);
  }, [sect]);

  const selectSubSect = async (sub) => {
    let res = await subSectApi(sub);
    if (res?.data?.status) {
      setSubsect(res?.data?.data);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });
      if (file) {
        setImage(file);
        // setValue('avatarUrl', newFile);
        setImages(URL.createObjectURL(file));
      }
    },
    [setValue]
  );

  const onSubmit = async (obj) => {
    if (
      arr.find((val) => val.number.length !== 10 && 10 !== val.number.length)
    ) {
      setShowMobileError(true);
    } else {
      setNewRow({ ...newRow, ...obj });
      let arrMob = [];
      var mobiles = item?.item?.mobiles;
      arr?.map((i, ind) => {
        arrMob.push(i.number);
        mobiles[ind].mobile = i.number;
      });
      let Edu = Array.isArray(obj?.education);
      let formData = new FormData();
      formData.append("id", showUpdate?.id);
      ///if (bioImage) formData.append("images", bioImage);
      if (name) formData.append("name", name);
      if (arrMob.length > 0) formData.append("mobile", arrMob.join(","));
      if (mobiles.length > 0)
        formData.append("mobiles", JSON.stringify(mobiles));
      if (gender) formData.append("gender", gender);
      if (employed) formData.append("employed_in", employed);
      if (Edu) formData.append("education", obj?.education?.join(","));
      if (obj?.country) formData.append("country", obj?.country);
      if (sect) formData.append("sect", sect);
      if (subSect) formData.append("sub_sect", subSect);
      if (manglik) formData.append("manglik", manglik);
      if (state) formData.append("state", state);
      if (marital) formData.append("marital_status", marital);
      if (disability) formData.append("physical_status", disability);
      if (dob) formData.append("dob", dob);
      formData.append("update_id", contxt?.user?.id);
      try {
        let res = await updateBiodata(formData);
        if (res?.data?.status) {
          enqueueSnackbar(res?.data?.message);
        } else enqueueSnackbar(res?.data?.message, { variant: "error" });
      } catch (error) {
        console.error(error);
        reset();
        setError("afterSubmit", {
          ...error,
          message: error.message,
        });
      }
    }
  };

  const openFullscreen = () => {
    let document = window.document;
    document.body.classList.add("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      AppContext?.setFullScreen(true);
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      AppContext?.setFullScreen(false);
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }

    // handle fullscreen exit
    const exitHandler = () => {
      if (
        !document.webkitIsFullScreen &&
        !document.mozFullScreen &&
        !document.msFullscreenElement
      ) {
        document.body.classList.remove("fullscreen-enable");
        AppContext?.setFullScreen(false);
      }
    };
    document.addEventListener("fullscreenchange", exitHandler);
    document.addEventListener("webkitfullscreenchange", exitHandler);
    document.addEventListener("mozfullscreenchange", exitHandler);
  };

  return (
    <>
      <Helmet>
        <title>Bio Data Entry Forms : Master Admin</title>
      </Helmet>

      <Grid container spacing={1}>
        <Grid item lg={9} md={9} sm={12} xs={12}>
          <Card>
            {" "}
            <TransformWrapper initialScale={1}>
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <React.Fragment>
                  <>
                    <Stack direction="row" justifyContent="space-around">
                      <Grid container spacing={1}>
                        <Grid item lg={7} md={12} sm={12} xs={12}>
                          <Stack direction="row" spacing={1} sx={{ mt: 2 }}>
                            <Button
                              color="primary"
                              variant="contained"
                              size="small"
                              sx={{
                                ml: 2,
                                typography: "body1",
                              }}
                              onClick={() =>
                                navigate(
                                  PATH_DASHBOARD.general.biodatadawnloading
                                )
                              }
                            >
                              Back
                            </Button>

                            <LoadingButton
                              size="small"
                              variant="contained"
                              color="inherit"
                              sx={{
                                mt: 1,
                                whiteSpace: "nowrap",
                                width: "40%",
                                typography: "body1",
                              }}
                            >
                              Used Bio: {biodataCount?.click}{" "}
                            </LoadingButton>

                            <LoadingButton
                              size="small"
                              variant="contained"
                              color="success"
                              sx={{
                                mt: 2,
                                whiteSpace: "nowrap",
                                width: "40%",
                                typography: "body1",
                              }}
                            >
                              Entries: {biodataCount?.entries}
                            </LoadingButton>

                            <LoadingButton
                              size="small"
                              variant="contained"
                              color="inherit"
                              sx={{
                                mt: 1,
                                whiteSpace: "nowrap",
                                width: "40%",
                                typography: "body1",
                              }}
                            >
                              Removed: {totalRemove ? totalRemove : 0}{" "}
                            </LoadingButton>

                            <LoadingButton
                              size="small"
                              variant="contained"
                              color="success"
                              sx={{
                                mt: 1,
                                whiteSpace: "nowrap",
                                width: "40%",
                                typography: "body1",
                              }}
                            >
                              ID :{" "}
                            </LoadingButton>
                          </Stack>
                        </Grid>
                        <Grid item lg={5} md={8} sm={12} xs={12} spacing={1}>
                          <Stack
                            direction="row"
                            justifySelf="center"
                            sx={{ marginTop: "16px" }}
                          >
                            <Iconify
                              icon="iconamoon:zoom-in"
                              onClick={() => zoomIn()}
                              width={30}
                            />
                            <Iconify
                              icon="iconamoon:zoom-out"
                              onClick={() => zoomOut()}
                              width={30}
                            />
                            <Iconify
                              icon="material-symbols:content-copy-outline"
                              onClick={() => {
                                openFullscreen();
                              }}
                              width={30}
                            />

                            <Button
                              size="small"
                              variant="contained"
                              sx={{ whiteSpace: "nowrap", marginRight: "8px" }}
                              color="inherit"
                            >
                              Bio remaining: {biodataCount?.remaining}
                            </Button>
                            {/* {!bioDataImages?.current ? <Button sx={{ marginRight: '10px' }} size='small' variant='contained' color='success' onClick={() => {
                                                        onAssign();
                                                    }
                                                    }>Assign Now</Button> : <Button size='small' sx={{ whiteSpace: "nowrap", marginRight: '8px' }} variant='contained' color='success' onClick={() =>
                                                        setAssignMsg("New Task Cannot Assign,Please Complete Current Task")
                                                    }>Assign Now</Button>} */}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>

                    <CardContent>
                      <Grid container alignItems={"center"}>
                        <Grid
                          item
                          lg={12}
                          md={8}
                          sm={8}
                          xs={8}
                          justifyContent={"center"}
                          display={"flex"}
                        >
                          <TransformComponent>
                            <Box
                              sx={{
                                width: !AppContext?.fullScreen
                                  ? "100%"
                                  : "100vw",
                                height: true ? "700px" : "580px",
                              }}
                            >
                              <Image
                                src={`${images ? images : blankUSer}`}
                                style={{
                                  height: "600px",
                                  width: "100vw",
                                  borderRadius: 1,
                                  objectFit: "contain",
                                }}
                              />
                            </Box>
                          </TransformComponent>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </>
                </React.Fragment>
              )}
            </TransformWrapper>
          </Card>
        </Grid>

        <Grid item lg={3} md={3} sm={12} xs={12}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={1} sx={{ px: 3 }}>
              <Scrollbar
                sx={{ maxHeight: !AppContext?.fullScreen ? 500 : 700 }}
              >
                <Grid container spacing={2} sx={{ ml: -1, pt: 2 }}>
                  <Grid item lg={12} md={12} xs={12} sm={12} sx={{ px: 1 }}>
                    <Grid container spacing={2}>
                      {arr?.map((item, index) => {
                        return (
                          <>
                            {" "}
                            <Grid
                              key={index}
                              item
                              lg={8}
                              md={12}
                              xs={12}
                              sm={12}
                            >
                              <Stack>
                                <RHFTextField
                                  value={arr[index].number}
                                  name="mobile"
                                  label="Mobile Number"
                                  size="small"
                                  sx={{ width: 230 }}
                                  type="number"
                                  onChange={(e) => {
                                    addNumber(index, e.target.value);
                                    if (e.target.value.length > 9) {
                                      setFilter(e.target.value);
                                    }
                                  }}
                                />
                                <Stack alignItems="flex-end">
                                  {index !== 0 && (
                                    <Iconify
                                      sx={{ mb: -4 }}
                                      onClick={() => {
                                        handleRemoveItem(index);
                                      }}
                                      icon="mdi:cancel-circle-outline"
                                    />
                                  )}
                                </Stack>
                              </Stack>
                              {showMobileError && (
                                <span
                                  style={{ fontSize: "12px", color: "red" }}
                                >
                                  Please Enter Only Ten Digits Numbers
                                </span>
                              )}
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={8} md={3} xs={6} sm={6} sx={{ px: 1 }}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={addInput}
                    sx={{
                      mt: 1,
                      bgcolor: "text.primary",
                      whiteSpace: "nowrap",
                      width: 100,
                      justifyContent: "center",
                      typography: "body2",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                      "&:hover": {
                        bgcolor: "text.primary",
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? "common.white"
                            : "grey.800",
                      },
                    }}
                  >
                    Add Number
                  </Button>
                </Grid>

                <Grid container spacing={2} sx={{ py: 2 }}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RHFTextField
                      name="name"
                      label="Name"
                      size="small"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RHFAutocomplete
                      name="gender"
                      freeSolo
                      size="small"
                      value={gender}
                      onChange={(newValue) => {
                        setGender(newValue);
                      }}
                      options={[
                        { id: 1, name: "Male" },
                        { id: 2, name: "Female" },
                      ]?.map((option) => option?.name)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option?.name}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Select Gender" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RHFAutocomplete
                      name="employed_in"
                      freeSolo
                      size="small"
                      value={employed}
                      onChange={(event, newValue) => {
                        setEmployed(newValue);
                      }}
                      options={Detail?.employedIn?.english?.map(
                        (option) => option?.label
                      )}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Profession" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Autocomplete
                      name="education"
                      freeSolo
                      multiple
                      size="small"
                      value={education}
                      onChange={(e, value) => {
                        setValue("education", value);
                        setEducation(value);
                      }}
                      options={Detail?.education?.map(
                        (option) => option?.name_english
                      )}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Select Education" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RHFAutocomplete
                      name="sect"
                      freeSolo
                      size="small"
                      value={sect}
                      onChange={(event, newValue) => setSect(newValue)}
                      options={[
                        { id: 1, name: "Digambar" },
                        { id: 2, name: "Shwetambar" },
                      ]?.map((option) => option?.name)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option?.name}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Select Sect" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RHFAutocomplete
                      name="sub_sect"
                      freeSolo
                      fullWidth
                      size="small"
                      value={subSect}
                      onChange={(event, newValue) => setSubSect(newValue)}
                      options={subsect?.map((option) => option?.english)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Sub Sect" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RHFAutocomplete
                      name="manglik"
                      freeSolo
                      size="small"
                      value={manglik}
                      onChange={(event, newValue) => setManglik(newValue)}
                      options={manlik?.map((option) => option?.name)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Manglik" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RHFAutocomplete
                      name="country"
                      freeSolo
                      defaultValue={"India"}
                      size="small"
                      onChange={(event, newValue) =>
                        setValue("country", newValue)
                      }
                      options={countryList?.map(
                        (option) => option?.name_english
                      )}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Select Country" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RHFAutocomplete
                      name="state"
                      freeSolo
                      size="small"
                      value={state}
                      onChange={(event, newValue) => setState(newValue)}
                      options={StateList?.map((option) => option?.english)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option?.english}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Select State" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RHFAutocomplete
                      name="marital_status"
                      freeSolo
                      size="small"
                      value={marital}
                      onChange={(event, newValue) => setMarital(newValue)}
                      options={maritalStatus?.map((option) => option?.name)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Marital status" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RHFAutocomplete
                      name="physical_status"
                      freeSolo
                      size="small"
                      value={disability}
                      onChange={(event, newValue) => setDisability(newValue)}
                      options={disabilitys?.map((option) => option?.name)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Disability" {...params} />
                      )}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <RHFAutocomplete
                      name="dob"
                      freeSolo
                      size="small"
                      value={dob}
                      onChange={(event, newValue) => setDob(newValue)}
                      options={year?.map((option) => option)}
                      renderTags={(value, getTagProps) =>
                        value?.map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option}
                            size="small"
                            label={option}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField label="Select" {...params} />
                      )}
                    />
                  </Grid>
                </Grid>
              </Scrollbar>

              <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    sx={{
                      ml: 2,
                      mt: 1,
                      bgcolor: "text.primary",
                      whiteSpace: "nowrap",
                      width: "90%",
                      justifyContent: "center",
                      typography: "body2",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                      "&:hover": {
                        bgcolor: "text.primary",
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? "common.white"
                            : "grey.800",
                      },
                    }}
                  >
                    Update{" "}
                  </Button>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      ml: 2,
                      mt: 1,
                      bgcolor: "text.primary",
                      whiteSpace: "nowrap",
                      width: "90%",
                      justifyContent: "center",
                      typography: "body2",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                      "&:hover": {
                        bgcolor: "text.primary",
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? "common.white"
                            : "grey.800",
                      },
                    }}
                  >
                    Remove
                  </Button>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      ml: 2,
                      mt: 1,
                      bgcolor: "text.primary",
                      whiteSpace: "nowrap",
                      width: "90%",
                      justifyContent: "center",
                      typography: "body2",
                      color: (theme) =>
                        theme.palette.mode === "light"
                          ? "common.white"
                          : "grey.800",
                      "&:hover": {
                        bgcolor: "text.primary",
                        color: (theme) =>
                          theme.palette.mode === "light"
                            ? "common.white"
                            : "grey.800",
                      },
                    }}
                  >
                    Move To Hindu
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </FormProvider>
        </Grid>
      </Grid>
    </>
  );
}
