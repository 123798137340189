import { useState } from "react";
import {
  IconButton,
  Grid,
  Box,
  CardHeader,
  Tooltip,
  Dialog,
  DialogContent,
  MenuItem,
  Card,
  TextField,
  CardContent,
  Button,
  Typography,
} from "@mui/material";
import Iconify from "../../components/iconify";
import { useSnackbar } from "notistack";
import { uploadImportedData } from "src/service";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import LoadingScreenSmall from "src/components/loading-screen/LoadingScreenSmall";
import FormProvider from "src/components/hook-form/FormProvider";
import * as React from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import { useAuthContext } from "src/auth/useAuthContext";
const TABLE_HEAD = [
  { id: "#", label: "#", align: "left" },
  { id: "reject", label: "Reject", align: "right" },
];

const TypeOption = [
  "Jain Matrimony",
  "Jain Basic",
  "Jain Special",
  "Jain Digambar",
  "Jain Shwetambar",
  "Mix Baniya Data",
  "Hindu Data",
  "Hindu & Jain",
  "Different Language",
  "Don't know",
  "Other",
];

const TypeOfGroup = [
  "Only Admin Can Send Information",
  "All can Send Information",
];

export default function ImportData({
  open,
  getOffineUsers,
  handleClose,
  groupId,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const methods = useForm({ resolver: yupResolver() });
  const user = JSON.parse(localStorage.getItem("sections"));
  const [inputData, setInputData] = useState("");
  const [indianData, setIndianData] = useState([]);
  const [response, setResponse] = useState(null);
  const [dataType, setDataType] = useState("");
  const [groupType, setGroupType] = useState("");
  const [isFilteringWithoutDuplicates, setIsFilteringWithoutDuplicates] =
    useState(false);
  const [groupName, setGroupName] = useState(
    groupId?.entry_data_group_name || ""
  );
  const contxt = useAuthContext();
  const permission = contxt?.user?.assignRole?.section?.find(
    (item) => item?.section === "groupLink"
  );
  const [loading, setLoading] = useState(false);
  const [nonIndianData, setNonIndianData] = useState([]);
  const {
    formState: {},
  } = methods;

  console.log(
    " user?.sections?.groupLink?.section?.duplicate  ::::",
    user?.sections?.groupLink?.["Duplicate Data Replace"]
  );

  // function filterPhoneNumbers(data) {
  //     const numbers = data.match(/\+\d{2}\s\d{5}\s\d{5}/g);

  //     if (!numbers) return;

  //     const indianNumbers = [];
  //     const nonIndianNumbers = [];

  //     numbers.forEach((number) => {
  //         if (number.startsWith('+91')) {
  //             indianNumbers.push(number);
  //         } else {
  //             nonIndianNumbers.push(number);
  //         }
  //     });

  //     setIndianData(indianNumbers);
  //     setNonIndianData(nonIndianNumbers);

  // }
  function filterPhoneNumbers(data) {
    const numbers = data.match(
      /\+\d{1,4}\s?\(?\d{1,4}\)?[-.\s]?\d{1,10}[-.\s]?\d{1,10}/g
    );
    if (!numbers) return;

    const indianNumbers = [];
    const nonIndianNumbers = [];

    numbers.forEach((number) => {
      if (number.startsWith("+91")) {
        indianNumbers.push(number);
      } else {
        nonIndianNumbers.push(number);
      }
    });
    setIndianData(indianNumbers);
    setNonIndianData(nonIndianNumbers);
  }

  const handleFilterButtonClick = () => {
    filterPhoneNumbers(inputData);
  };

  const onDataUpload = async () => {
    setLoading(true);

    let obj = {
      section: "offline",
      bharatNum: indianData,
      outOfBharatNum: nonIndianData,
      group_name: groupName,
      type_of_data: dataType,
      group_type: groupType,
      groupId: groupId?.id,
    };

    if (isFilteringWithoutDuplicates) {
      obj.duplicate_replace = "yes";
    }

    try {
      const res = await uploadImportedData(obj);
      if (res.data.status) {
        setLoading(false);
        setIndianData([]);
        setNonIndianData([]);
        setResponse(res.data.data);
        enqueueSnackbar("Data Uploaded Successfully");
      } else {
        setLoading(false);
        enqueueSnackbar(res.data.message || "Upload Failed", {
          variant: "error",
        }); // Show server message
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(error.message || "An error occurred", {
        variant: "error",
      }); // Improved error handling
    }
    setIsFilteringWithoutDuplicates(false);
  };

  // console.log("DATATYPE ::", dataType);
  // console.log("GroupName ::", groupName);

  const resetModal = () => {
    setInputData("");
    setIndianData([]);
    setNonIndianData([]);
    setResponse(null);
    setDataType("");
    setGroupType("");
    setGroupName("");
    getOffineUsers();
  };

  const buttonStyle = {
    color: "red", // Default text color
    backgroundColor: "white", // Default background color
    "&:hover": {
      backgroundColor: "lightgray", // Slightly darker on hover
    },
  };

  const activeButtonStyle = {
    color: "white",
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "darkred", // Slightly darker on hover
    },
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        resetModal();
      }}
      fullWidth
      maxWidth={"md"}
    >
      <CardHeader
        action={
          <Tooltip title="Close">
            <IconButton
              color="error"
              className="CloseBtn"
              onClick={() => {
                handleClose();
                resetModal();
              }}
            >
              <Iconify icon="mdi:cancel-circle-outline" />
            </IconButton>
          </Tooltip>
        }
        title={`Import Data`}
        sx={{ textAlign: "center" }}
      />
      <DialogContent>
        {indianData.length === 0 && response === null && (
          <FormProvider methods={methods}>
            <Card>
              <div className="row d-flex justify-content-center my-2">
                <Button
                  onClick={() => setIsFilteringWithoutDuplicates(false)}
                  variant="contained"
                  sx={
                    !isFilteringWithoutDuplicates
                      ? activeButtonStyle
                      : buttonStyle
                  } // Apply styles
                >
                  With Duplicate Data
                </Button>
                {user?.sections?.groupLink?.["Duplicate Data Replace"] && (
                  <Button
                    onClick={() => setIsFilteringWithoutDuplicates(true)}
                    variant="contained"
                    sx={
                      isFilteringWithoutDuplicates
                        ? activeButtonStyle
                        : buttonStyle
                    }
                  >
                    Without Duplicate Data
                  </Button>
                )}
              </div>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item sm={10} xs={10} md={4} lg={4}>
                    <TextField
                      required
                      label="Group Name"
                      value={groupName}
                      onChange={(e) => setGroupName(e.target.value)}
                      error={!groupName}
                      helperText={!groupName ? "Group Name is required" : ""}
                    />
                  </Grid>
                  <Grid item sm={10} xs={10} md={4} lg={4}>
                    <TextField
                      required
                      native
                      fullWidth
                      select
                      name="type_of_data"
                      label="Type of data"
                      value={dataType}
                      onChange={(e) => setDataType(e.target.value)}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                      error={!dataType}
                      helperText={!dataType ? "Type of data is required" : ""}
                    >
                      {TypeOption.map((option) => (
                        <MenuItem
                          key={option}
                          value={option}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {/* type of group */}
                  <Grid item sm={10} xs={10} md={4} lg={4}>
                    <TextField
                      required
                      native
                      fullWidth
                      select
                      name="group_type"
                      label="Type of Group"
                      value={groupType}
                      onChange={(e) => setGroupType(e.target.value)}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                      error={!groupType}
                      helperText={!groupType ? "Type of data is required" : ""}
                    >
                      {TypeOfGroup.map((option) => (
                        <MenuItem
                          key={option}
                          value={option}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item sm={12} xs={12} md={12} lg={12}>
                    <MinHeightTextarea
                      inputData={inputData}
                      setInputData={setInputData}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <div className="row d-flex justify-content-center my-2">
              <Button
                onClick={handleFilterButtonClick}
                sx={{ width: "auto", background: "#212b35" }}
                size={"medium"}
                variant={"contained"}
              >
                Filter Data
              </Button>
            </div>
          </FormProvider>
        )}
        {indianData.length > 0 && (
          <div>
            <Card style={{ marginTop: "20px", display: "flex" }}>
              <CardContent style={{ flex: "1", textAlign: "center" }}>
                <Typography color={"#fa3232"} variant="h6" gutterBottom>
                  {`Indian User(${indianData.length})`}
                </Typography>
                <p style={{ maxHeight: "280px", overflowY: "auto" }}>
                  {indianData.map((number, index) => (
                    <p key={index}>{number}</p>
                  ))}
                </p>
              </CardContent>
              <CardContent style={{ flex: "1", textAlign: "center" }}>
                <Typography color={"#fa3232"} variant="h6" gutterBottom>
                  {`Out of India User(${nonIndianData.length})`}
                </Typography>
                <p style={{ maxHeight: "280px", overflowY: "auto" }}>
                  {nonIndianData.map((number, index) => (
                    <p key={index}>{number}</p>
                  ))}
                </p>
              </CardContent>
            </Card>
            <div className="row d-flex justify-content-center my-2">
              <Button
                variant="contained"
                sx={{ width: "auto", background: "#212b35" }}
                onClick={onDataUpload}
              >
                Submit
              </Button>
            </div>
          </div>
        )}
        {response !== null && indianData.length === 0 && (
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  mb={1}
                  md={12}
                  borderRadius={"10px 10px 0 0"}
                  sx={{
                    background: "#0085f8",
                    textAlign: "center",
                    pb: 1,
                    pt: 0,
                  }}
                >
                  <Typography
                    color={"white"}
                    sx={{
                      diaplay: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    fontWeight={"bold"}
                    w={"100%"}
                  >
                    Total : {response?.totalUser}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    background: "#b97a57",
                    textAlign: "center",
                    pb: 1,
                    pt: 0,
                  }}
                  md={6}
                >
                  <Typography
                    color={"white"}
                    sx={{
                      diaplay: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    fontWeight={"bold"}
                    w={"100%"}
                  >
                    Indian User : {response?.Bharat?.total_User}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    background: "#fbad15",
                    textAlign: "center",
                    pb: 1,
                    pt: 0,
                  }}
                  md={6}
                >
                  <Typography
                    color={"white"}
                    sx={{
                      diaplay: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    fontWeight={"bold"}
                    w={"100%"}
                  >
                    Out of India User : {response?.OutOfBharat?.total_User}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        flex: "1",
                        textAlign: "center",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Typography
                        w={"100%"}
                        color={"white"}
                        sx={{ background: "#09c660" }}
                        fontWeight={"bold"}
                      >
                        Replace Number : {response?.Bharat?.addUser?.length}
                      </Typography>
                      <p style={{ maxHeight: "280px", overflowY: "auto" }}>
                        {response?.Bharat?.addUser?.map((number, index) => (
                          <p key={index}>{number}</p>
                        ))}
                      </p>
                    </div>
                    <div style={{ flex: "1", textAlign: "center" }}>
                      <Typography
                        w={"100%"}
                        color={"white"}
                        fontWeight={"bold"}
                        sx={{ background: "#ff0000" }}
                      >
                        Duplicate : {response?.Bharat?.rejectUser?.length}
                      </Typography>
                      <p style={{ maxHeight: "280px", overflowY: "auto" }}>
                        {response?.Bharat?.rejectUser?.map((number, index) => (
                          <p key={index}>{number}</p>
                        ))}
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} md={6}>
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        flex: "1",
                        textAlign: "center",
                        borderRight: "1px solid black",
                      }}
                    >
                      <Typography
                        w={"100%"}
                        color={"white"}
                        fontWeight={"bold"}
                        sx={{ background: "#09c660" }}
                      >
                        Replace Number :{" "}
                        {response?.OutOfBharat?.addUser?.length}
                      </Typography>
                      <p style={{ maxHeight: "280px", overflowY: "auto" }}>
                        {response?.OutOfBharat?.addUser?.map(
                          (number, index) => (
                            <p key={index}>{number}</p>
                          )
                        )}
                      </p>
                    </div>
                    <div style={{ flex: "1", textAlign: "center" }}>
                      <Typography
                        w={"100%"}
                        color={"white"}
                        fontWeight={"bold"}
                        sx={{ background: "#ff0000" }}
                      >
                        Duplicate : {response?.OutOfBharat?.rejectUser?.length}
                      </Typography>
                      <p style={{ maxHeight: "280px", overflowY: "auto" }}>
                        {response?.OutOfBharat?.rejectUser?.map(
                          (number, index) => (
                            <p key={index}>{number}</p>
                          )
                        )}
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          {loading && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <LoadingScreenSmall />
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export function MinHeightTextarea({ inputData, setInputData }) {
  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#F3F6F9",
    100: "#E5EAF2",
    200: "#DAE2ED",
    300: "#C7D0DD",
    400: "#B0B8C4",
    500: "#9DA8B7",
    600: "#6B7A90",
    700: "#434D5B",
    800: "#303740",
    900: "#1C2025",
  };

  const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };
    
    &:hover {
      border-color: ${blue[400]};
    }
    
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[600] : blue[200]
      };
    }
    
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  return (
    <>
      <div style={{ width: "100%", marginTop: "10px", marginBottom: "5px" }}>
        <div style={{ width: "100%", marginTop: "10px", marginBottom: "5px" }}>
          <Textarea
            minRows={8}
            value={inputData}
            onChange={(e) => setInputData(e.target.value)}
          />
        </div>
      </div>
    </>
  );
}
