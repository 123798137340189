import PropTypes from "prop-types";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Stack,
  AppBar,
  Toolbar,
  TextField,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  CardHeader,
  Tooltip,
  Grid,
  Typography,
} from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// config
import { HEADER, NAV } from "../../../config";
// components
import Logo from "../../../components/logo";
import Iconify from "../../../components/iconify";
import { useSettingsContext } from "../../../components/settings";
import AccountPopover from "./AccountPopover";
import { PATH_DASHBOARD } from "src/routes/paths";
import NotificationsPopover from "./NotificationsPopover";
import { useState, useEffect, useCallback } from "react";
import ImportData from "src/pages/General/GlobalSearch";
import { useLocation, useNavigate } from "react-router-dom";
import constant from "../../../Constant";
import {
  addScannerDataApi,
  createUniversalPaymentLinkApi,
  getBiodataListApi,
  getGroupServiceListApi,
  getIncomingServiceListApi,
  getPaidPromotionListApi,
  getPendingOfflineUsersList,
  getPendingOnlineUsersListFiltered,
  getSocialMediaListApi,
  getWhatsappResponseListApi,
  loginStatusApi,
  shareTaskCount,
  updateImagesDataScanner,
} from "src/service";
import { useAuthContext } from "src/auth/useAuthContext";
import { useSnackbar } from "notistack";
import useCopyToClipboard from "src/hooks/useCopyToClipboard";

// import { getGlobalSearch } from 'src/service';
// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const { themeLayout } = useSettingsContext();
  const isNavHorizontal = themeLayout === "horizontal";
  const navigate = useNavigate();
  const { user, device, logout } = useAuthContext();
  const isNavMini = themeLayout === "mini";
  const isDesktop = useResponsive("up", "lg");
  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;
  const [open, setOpen] = useState(false);
  const [call, setCall] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [shareCardCount, setShareCardCount] = useState(0);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  // console.log('setShareCardCount ::::', shareCardCount)
  const [data, setData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileOn, setIsMobileOn] = useState(false);
  const [amount, setAmount] = useState(0);
  const [mobile, setMobile] = useState(null);
  const [type, setType] = useState("jain");
  const [linkList, setLinkList] = useState([]);
  const { copy } = useCopyToClipboard();

  const handleLinkGenerete = async () => {
    if (!amount || !type) {
      enqueueSnackbar("Please enter amount and type", { variant: "error" });
      return;
    }

    try {
      const res = await createUniversalPaymentLinkApi({
        amount: Number(amount),
        mobile: mobile,
        type: type,
      });

      if (res?.data?.status) {
        setLinkList([...linkList, res?.data?.data]);
        setAmount(0);
        setMobile(null);
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    }
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const handleModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (
      window.location.pathname === "/onlineuser" ||
      window.location.pathname === "/shareTask"
    ) {
      shareCount();
    }
  }, []);

  const updateLoginStatus = async () => {
    try {
      const response = await loginStatusApi({
        id: user?.id,
        login_device_detail: device,
      });
      if (response?.data?.data?.log_out_status) {
        logout();
        window.location.reload();
      }
    } catch (error) {
      console.error("Error updating login status:", error);
    }
  };

  useEffect(() => {
    setCall(!call);
    // updateLoginStatus();
  }, [location.pathname]);

  const handleUpdateBiodata = async () => {
    const res = await updateImagesDataScanner();
    if (res?.data?.status) {
      let imgData = res?.data?.data?.map((img) => {
        return {
          name: null,
          mobile: img?.mobile,
          state: img?.states,
          image: img?.image_address,
        };
      });
      const dataUploadResponse = await addScannerDataApi(imgData);
      if (dataUploadResponse?.data?.status) {
        enqueueSnackbar(dataUploadResponse?.data?.message);
      }
    }
  };

  // const getPendingCount = useCallback(async () => {
  //   try {
  //     const promises = [
  //       handleSectionClick(0, 5),
  //       getPendingRavcListData(0, 25, { key: "registration" }),

  //     ];

  //     const responses = await Promise.all(promises);

  //     let count = responses.reduce((total, res) => {
  //       return total + Number(res?.data?.data?.total || 0);
  //     }, 0);

  //     setPendingCount(count);
  //   } catch (error) {
  //     console.error("Error while fetching pending counts:", error);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (
  //     location.pathname === "/onlineuser" ||
  //     location.pathname === "/pendingTask"
  //   ) {
  //     getPendingCount();
  //   }
  // }, [location.pathname, call, getPendingCount]);

  const shareCount = async () => {
    const res = await shareTaskCount();
    if (res?.data?.status) {
      setShareCardCount(res?.data?.data);
    }
  };

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: "text.primary" }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      {/* <Searchbar /> */}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* <Button
          variant="contained"
          onClick={() => navigate(PATH_DASHBOARD.general.userRequest)}
        >
          User's Request (0)
        </Button> */}
        {(window.location.pathname === "/onlineuser" ||
          window.location.pathname === "/shareTask") && (
          <Button
            variant="contained"
            sx={{ fontSize: { md: "0.8rem", sm: "0.6rem", xs: "0.6rem" } }}
            onClick={() => {
              if (window.location.pathname === "/shareTask") {
                navigate(PATH_DASHBOARD.general.onlineuser);
              } else {
                navigate(PATH_DASHBOARD.general.shareTask);
              }
            }}
          >
            Shared Task ({shareCardCount})
          </Button>
        )}

        {window.location.pathname === "/newGlobalSearch" && (
          <Button
            variant="contained"
            sx={{ fontSize: { md: "0.8rem", sm: "0.6rem", xs: "0.6rem" } }}
            onClick={handleModalOpen}
          >
            Universal Payment Link
          </Button>
        )}

        {/*<Button variant="contained" onClick={updateWithGpt}>
          Gpt Click
        </Button>*/}

        {(window.location.pathname === "/onlineuser" ||
          window.location.pathname === "/pendingTask") && (
          <Button
            variant="contained"
            sx={{ fontSize: { md: "0.8rem", sm: "0.6rem", xs: "0.6rem" } }}
            onClick={() => {
              if (window.location.pathname === "/pendingTask") {
                navigate(PATH_DASHBOARD.general.onlineuser);
              } else {
                navigate(PATH_DASHBOARD.general.pendingTask);
              }
            }}
          >
            Pending Task ({pendingCount})
          </Button>
        )}

        {constant?.updateBiodata && (
          <Button variant="contained" onClick={handleUpdateBiodata}>
            Update Biodata
          </Button>
        )}

        {window.location.pathname !== "/incominguseradd" &&
          window.location.pathname !== "/incominguser" &&
          !user?.sections?.ScannerData?.WorkFromHomeStaff &&
          !user?.sections?.HinduScannerData?.WorkFromHomeStaff && (
            <Button
              className="mx-1"
              variant="contained"
              onClick={() => navigate(PATH_DASHBOARD?.general?.incominguseradd)}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Incoming
            </Button>
          )}

        {/* {contxt?.user?.sections['Global Search']['Master Admin Access'] && <GlobalSearch />} */}
        {!user?.sections?.ScannerData?.WorkFromHomeStaff &&
          !user?.sections?.HinduScannerData?.WorkFromHomeStaff && (
            <Button
              variant="contained"
              sx={{ fontSize: { md: "0.8rem", sm: "0.6rem", xs: "0.6rem" } }}
              onClick={() => {
                navigate("/newGlobalSearch");
              }}
            >
              Global Search
            </Button>
          )}

        {/* <GlobalSearch /> */}
        <NotificationsPopover />
        <AccountPopover />

        {/* <ContactsPopover /> */}
      </Stack>
      <ImportData open={open} data={data} handleClose={handleClose} />
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: "background.default",
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar>{renderContent}</Toolbar>
      <Dialog
        open={isModalOpen}
        onClose={handleModalOpen}
        maxWidth="sm"
        fullWidth
      >
        {/* Header */}
        <CardHeader
          action={
            <Tooltip title="Close">
              <IconButton className="CloseBtn" onClick={handleModalOpen}>
                <Iconify icon="mdi:cancel-circle-outline" />
              </IconButton>
            </Tooltip>
          }
          title={
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "white", textAlign: "center" }}
            >
              Universal Payment Link
            </Typography>
          }
          sx={{ backgroundColor: "red", color: "white", padding: "10px 15px" }}
        />

        {/* Content */}
        <DialogContent>
          <Stack spacing={2} mb={3}>
            {/* Buttons Row */}
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6}>
                <Button
                  variant={type === "jain" ? "contained" : "outlined"}
                  sx={{ width: "100%" }}
                  onClick={() => setType("jain")}
                >
                  Jain
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant={type === "hindu" ? "contained" : "outlined"}
                  sx={{ width: "100%" }}
                  onClick={() => setType("hindu")}
                >
                  Hindu
                </Button>
              </Grid>
            </Grid>

            {/* Mobile Number Buttons */}
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={6}>
                <Button
                  variant={isMobileOn ? "outlined" : "contained"}
                  sx={{
                    width: "100%",
                  }}
                  color="info"
                  onClick={() => {
                    setIsMobileOn(false);
                  }}
                >
                  Without Mobile No.
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant={isMobileOn ? "contained" : "outlined"}
                  sx={{ width: "100%" }}
                  color="info"
                  onClick={() => {
                    setIsMobileOn(true);
                  }}
                >
                  With Mobile No.
                </Button>
              </Grid>
            </Grid>

            {/* Input Field */}
            {isMobileOn && (
              <TextField
                placeholder="Enter Mobile"
                variant="outlined"
                fullWidth
                type="tel"
                inputProps={{ maxLength: 10 }}
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                sx={{ marginTop: "10px" }}
              />
            )}
            <TextField
              placeholder="Enter Amount"
              variant="outlined"
              fullWidth
              type="tel"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              sx={{ marginTop: "10px" }}
            />

            {/* Generate Link Button */}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FF9800",
                color: "white",
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#F57C00" },
              }}
              disabled={!amount}
              onClick={handleLinkGenerete}
            >
              Generate link
            </Button>

            {/* Links Section */}
            <Stack spacing={1}>
              {linkList.map((data, index) => (
                <Grid container spacing={1} alignItems="center" key={index}>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", color: "red" }}
                    >
                      Mobile: {data?.customer?.contact}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ color: "green", borderColor: "green" }}
                      onClick={() => {
                        copy(data?.short_url);
                        enqueueSnackbar("Link Copied", { variant: "success" });
                      }}
                    >
                      Copy
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      sx={{ color: "blue", wordWrap: "break-word" }}
                    >
                      Link: {data?.short_url}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </AppBar>
  );
}
