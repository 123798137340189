import PropTypes from "prop-types";
import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useState,
} from "react";
import { authorizeMe, loginStatusApi, logoutUser, me, signIn } from "src/service";
// utils
import axios from "axios";
//
import { isValidToken, setSession } from "./utils";
import { useAuthContext } from "./useAuthContext";
import platform from "platform";
// import { assignRole } from "src/Constant";

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  deviceInfo: "",
};

const reducer = (state, action) => {
  if (action.type === "INITIAL") {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGIN") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === "REGISTER") {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === "LOGOUT") {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === "SET_DEVICE") {
    return {
      ...state,
      deviceInfo: action.payload.deviceInfo,
    };
  }

  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext(null);

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fullScreen, setFullScreen] = useState(false);
  const [device, setDevice] = useState(null);

  useEffect(() => {
    const deviceInfo = platform.parse(navigator.userAgent);
    const loginDevice = `${deviceInfo?.name?.replace(
      /\s/g,
      ""
    )}-${deviceInfo?.os?.family?.replace(
      /\s/g,
      ""
    )}-${deviceInfo?.os?.version?.replace(/\s/g, "")}-${"web"?.replace(
      /\s/g,
      ""
    )}`;
    setDevice(loginDevice);
    dispatch({
      type: "SET_DEVICE",
      payload: {
        deviceInfo: loginDevice,
      },
    });
  }, []);

  const initialize = useCallback(async () => {
    try {
      const accessToken =
        typeof window !== "undefined"
          ? localStorage.getItem("accessToken")
          : "";
      if (accessToken) {
        setSession(accessToken);
        const response = await me();
        const { user, assignRole } = response.data.data;
        // user?.assignRole?.section?.map((val) => {
        //   obj[val.section] = val;
        // });
        // user.sections = obj;

        const mergedSections = compareAndMergeSections(assignRole);
        user.sections = mergedSections;

        if (user.public_view === 0) {
          window.location.reload();
          logout();
          return;
        }
        localStorage.setItem("sections", JSON.stringify(user));
        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: "INITIAL",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "INITIAL",
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  // const login = async (email, password) => {
  //     const response = await signIn({ mobile: email, password })
  //     const { accessToken, users } = response?.data?.data;
  //   var obj = {}
  //   users?.assignRole?.section?.map(val => {
  //     obj[val.section] = val;
  //   })
  //   users.sections = obj;
  //   setSession(accessToken);
  //   localStorage.setItem('sections', JSON.stringify(users));
  //   // authorizeMe(accessToken)
  //   dispatch({
  //     type: 'LOGIN',
  //     payload: {
  //       user: users
  //     },
  //   });
  // };

  const compareAndMergeSections = (roles) => {
    const mergedSections = {};
    roles?.forEach((roleObj) => {
      roleObj.section.forEach((sectionObj) => {
        const sectionName = sectionObj.section;

        if (!mergedSections[sectionName]) {
          mergedSections[sectionName] = { ...sectionObj };
        } else {
          Object.keys(sectionObj).forEach((key) => {
            if (key === "section") return;

            if (!(key in mergedSections[sectionName])) {
              mergedSections[sectionName][key] = sectionObj[key];
            } else {
              mergedSections[sectionName][key] =
                mergedSections[sectionName][key] || sectionObj[key];
            }
          });
        }
      });
    });

    return mergedSections;
  };

  const login = async (email, password) => {
    try {
      const response = await signIn({
        mobile: email,
        password,
        login_device_detail: device,
      });
      if (!response.data?.status) {
        return response.data?.errors;
      }
      if (response.data?.data?.users.public_view !== 0) {
        const { accessToken, users, assignRole } = response?.data?.data;
        // console.log("swapnil",response?.data?.data)

        var obj = {};
        // users?.assignRole?.section?.map((val) => {
        //   obj[val.section] = val;
        // });
        // users.sections = obj;
        const mergedSections = compareAndMergeSections(assignRole);
        users.sections = mergedSections;
        // assignRole.forEach((item) => {
        //   item.section.forEach((sectionItem) => {
        //     const { section, ...permissions } = sectionItem;

        //     if (!obj[section]) {
        //       obj[section] = {
        //         section,
        //         ...permissions,
        //       };
        //     } else {
        //       Object.assign(obj[section], permissions);
        //     }
        //   });
        // });

        // users.sections = obj;
        setSession(accessToken);
        localStorage.setItem("sections", JSON.stringify(users));
        // authorizeMe(accessToken)
        dispatch({
          type: "LOGIN",
          payload: {
            user: users,
          },
        });
      } else {
        const errorMessage = "User does not have  access.";
        console.log(errorMessage);
        localStorage.setItem("loginErrorMessage", errorMessage);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // REGISTER
  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/account/register", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;
    localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };



  // setUserData
  const setUserData = async (user) => {
    localStorage.setItem("sections", JSON.stringify(user));
    dispatch({
      type: "INITIAL",
      payload: {
        isAuthenticated: true,
        user,
      },
    });
  };

  // LOGOUT
  const logout = async () => {
    let user = localStorage.getItem("sections");
    await logoutUser({
      id: JSON.parse(user)?.id,
      login_device_detail: device,
    });
    setSession(null);
    dispatch({
      type: "LOGOUT",
    });
  };



  const maskPhoneNumber = (phoneNumber) => {
    if (state?.user?.isNumberMaskedOn && phoneNumber) {
      const cleanedPhoneNumber = phoneNumber
        .toString()
        .trim()
        .replace(/[^+\d]/g, "");
      if (cleanedPhoneNumber.length < 10 || cleanedPhoneNumber.length > 13) {
        return phoneNumber;
      }
      const maskedPhoneNumber = cleanedPhoneNumber.slice(0, -4) + "****";

      return maskedPhoneNumber;
    } else return phoneNumber;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        fullScreen,
        setFullScreen,
        login,
        logout,
        register,
        setUserData,
        maskPhoneNumber,
        device,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
