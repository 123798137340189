import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";

import Iconify from "src/components/iconify/Iconify";
import OfflineGlobalCard from "src/sections/@dashboard/user/cards/OfflineGlobalCard";
import BiodataGobalCard from "src/sections/@dashboard/user/cards/BiodataGobalCard";
import OnlineGlobalCard from "src/sections/@dashboard/user/cards/OnlineGlobalCard";
import WhatsappResGobalCard from "src/sections/@dashboard/user/cards/WhatsappResGobalCard";
import GroupServiceGlobalCard from "src/sections/@dashboard/user/cards/GroupServiceGlobalCard";
import SocialMediaGlobalCard from "src/sections/@dashboard/user/cards/SocialMediaGlobalCard";
import PaidPromGlobalCard from "src/sections/@dashboard/user/cards/PaidPromGlobalCard";
import IncomingGlobalCard from "src/sections/@dashboard/user/cards/IncomingGlobalCard";
import { UniversalSearchUser } from "src/service";
const NewGlobalSearch = () => {
  const [loading, setLoading] = useState(false);
  const [mobileValue, setMobileValue] = useState();
  const [open, setOpen] = useState(false);
  const [onlineUserList, setOnlineUserList] = useState([]);
  const [offlineUserList, setOfflineUserList] = useState([]);
  const [bioDataUserList, setBioDataUserList] = useState([]);
  const [whatsappResponseList, setWhatsappResponseList] = useState([]);
  const [incomingGlobalCallList, setIncomingGlobalCallList] = useState([]);
  const [paidPromotionUserList, setPaidPromotionUserList] = useState([]);
  const [groupServiceUserList, setGroupServiceUserList] = useState([]);
  const [socialMediaUserList, setSocialMediaUserList] = useState([]);
  const [data, setData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [searching, setSearching] = useState(false);
  const [SelectSearch, setSelectSearch] = useState({
    type: "mobile",
    value: "",
  });
  const sectionButtons = [
    { label: "Online", section: "online" },
    { label: "Offline", section: "offline" },
    { label: "Biodata", section: "biodata" },
    { label: "Whatsapp Response", section: "whatsapp_response" },
    { label: "Group Service", section: "group_service" },
    { label: "Social Media", section: "social_media" },
    { label: "Paid Promotion", section: "paid_promotion" },
    { label: "Incoming", section: "incoming_call" },
  ];

  const toggleSection = (section) => {
    setSelectedCategory((prevSections) => {
      if (prevSections.includes(section)) {
        return prevSections.filter((s) => s !== section);
      } else {
        return [...prevSections, section];
      }
    });
  };
  const handleClose = () => {
    setOpen(!open);
    setMobileValue("");
  };
  const globalsearch = async () => {
    setLoading(true);
    let res = await UniversalSearchUser({ mobile: mobileValue });
    console.log("res ::::", res);
    if (res?.data?.status) {
      setOnlineUserList(res?.data?.data[0]?.onlineUser || []);
      setOfflineUserList(res?.data?.data[1]?.offlineUser || []);
      setBioDataUserList(res?.data?.data[2]?.BioDataUser || []);
      setWhatsappResponseList(res?.data?.data[3]?.whatsappResponse || []);
      setIncomingGlobalCallList(res?.data?.data[4]?.incomingCall || []);
      setSocialMediaUserList(res?.data?.data[5]?.socialMedia || []);
      setGroupServiceUserList(res?.data?.data[6]?.groupService || []);
      setPaidPromotionUserList(res?.data?.data[7]?.paidPromotion || []);
    }
    setLoading(false);
  };

  const onSubmit = async (values) => {
    if (values?.value === "") {
      setLoading(false);
      setData([]);
      return;
    }

    setSearching(true);

    var obj = {};
    obj[values?.type] = values?.value;
    obj["section"] = selectedCategory;
    var res = await UniversalSearchUser(obj);
    console.log("res ::::", res);

    if (res?.data?.status) {
      setLoading(false);
      setOfflineUserList(res?.data?.data?.offline?.offline);
      setOnlineUserList(res?.data?.data?.online?.online);
      setBioDataUserList(res?.data?.data?.biodata?.biodata);
      setWhatsappResponseList(
        res?.data?.data?.whatsapp_response?.whatsapp_response
      );
      setIncomingGlobalCallList(res?.data?.data?.incomingCall?.incoming_call);
      setSocialMediaUserList(res?.data?.data?.socialMedia?.social_media);
      setGroupServiceUserList(res?.data?.data?.groupService?.group_service);
      setPaidPromotionUserList(res?.data?.data?.paidPromotion?.paid_promotion);
    }
    setSearching(false);
  };

  return (
    <div>
      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <Button
          variant={SelectSearch?.type === "mobile" ? "contained" : "outline"}
          onClick={() => setSelectSearch({ ...SelectSearch, type: "mobile" })}
          sx={{ flex: 1 }}
        >
          Mobile
        </Button>
        <Button
          variant={SelectSearch?.type === "id" ? "contained" : "outline"}
          onClick={() => setSelectSearch({ ...SelectSearch, type: "id" })}
          sx={{
            flex: 1,
            border: "1px solid red",
            color: SelectSearch?.type === "id" ? "white" : "red",
          }}
        >
          ID
        </Button>

        {/* <TextField
          fullWidth
          type="tel"
          maxLength={10}
          placeholder="Enter Mobile Number"
          onClick={() => setSelectSearch({ ...SelectSearch, type: "mobile" })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            ),
          }}
        /> */}
        <TextField
          fullWidth
          type="text"
          placeholder="Enter Mobile/Id number"
          value={SelectSearch?.value}
          //           onClick={() => setSelectSearch({ ...SelectSearch, type: "id" })}
          onChange={(event) => {
            const inputValue = event.target.value;
            if (inputValue === "") {
              onSubmit({ value: "" });
            }
            setSelectSearch({
              ...SelectSearch,
              value: inputValue,
            });
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          sx={{
            backgroundColor: "red",
            color: "white",
            "&:hover": { backgroundColor: "darkred" },
            width: "150px", // Increased button width
          }}
          onClick={() => onSubmit(SelectSearch)}
        >
          Search
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          mb: 2,
          overflowX: "auto",
          flexWrap: "nowrap",
          marginLeft: "auto",
          marginRight: "auto",
          width: "fit-content",
        }}
      >
        <Button
          size="small"
          color="primary"
          variant={
            selectedCategory?.length ===
            (sectionButtons?.map((button) => button.section) || []).length
              ? "contained"
              : "outlined"
          }
          onClick={() => {
            const allSections =
              sectionButtons?.map((button) => button.section) || [];
            if (selectedCategory?.length === allSections.length) {
              setSelectedCategory([]);
            } else {
              setSelectedCategory(allSections);
            }
          }}
          sx={{
            m: 0.5,
            color:
              selectedCategory?.length ===
              (sectionButtons?.map((button) => button.section) || []).length
                ? "white"
                : "red",
            borderColor: "red",
            backgroundColor:
              selectedCategory?.length ===
              (sectionButtons?.map((button) => button.section) || []).length
                ? "red"
                : "transparent",
            "&:hover": {
              backgroundColor:
                selectedCategory?.length ===
                (sectionButtons?.map((button) => button.section) || []).length
                  ? "darkred"
                  : "transparent",
            },
            border: "1px solid",
            padding: "15px",
          }}
        >
          Global Search
        </Button>
        {sectionButtons?.map((button) => (
          <Button
            key={button?.section}
            value={button?.section}
            size="small"
            color="primary"
            variant={
              selectedCategory?.includes(button?.section)
                ? "contained"
                : "outlined"
            }
            onClick={() => toggleSection(button?.section)}
            sx={{
              border: "1px solid",
              padding: "15px",
              marginTop: "2px",
            }}
          >
            {button.label}
          </Button>
        ))}
      </Box>

      {searching ? (
        <div
          className="displayCenterProp"
          style={{
            height: "calc(100vh - 150px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {(!onlineUserList || onlineUserList?.length === 0) &&
          (!offlineUserList || offlineUserList?.length === 0) &&
          (!bioDataUserList || bioDataUserList?.length === 0) &&
          (!whatsappResponseList || whatsappResponseList?.length === 0) &&
          (!groupServiceUserList || groupServiceUserList?.length === 0) &&
          (!socialMediaUserList || socialMediaUserList?.length === 0) &&
          (!paidPromotionUserList || paidPromotionUserList?.length === 0) &&
          (!incomingGlobalCallList || incomingGlobalCallList?.length === 0) ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <p className="my-5 text-center">
                <b>No Data Found!</b>
              </p>
            </div>
          ) : null}

          {onlineUserList?.length > 0 && (
            <OnlineGlobalCard onlineUserList={onlineUserList} show={true} />
          )}
          {offlineUserList?.length > 0 && (
            <OfflineGlobalCard offlineUserList={offlineUserList} show={true} />
          )}
          {bioDataUserList?.length > 0 && (
            <BiodataGobalCard bioDataUserList={bioDataUserList} show={true} />
          )}
          {whatsappResponseList?.length > 0 && (
            <WhatsappResGobalCard
              whatsappResponseList={whatsappResponseList}
              show={true}
            />
          )}
          {groupServiceUserList?.length > 0 && (
            <GroupServiceGlobalCard
              groupServiceUserList={groupServiceUserList}
              show={true}
            />
          )}
          {socialMediaUserList?.length > 0 && (
            <SocialMediaGlobalCard
              socialMediaUserList={socialMediaUserList}
              show={true}
            />
          )}
          {paidPromotionUserList?.length > 0 && (
            <PaidPromGlobalCard
              paidPromotionUserList={paidPromotionUserList}
              show={true}
            />
          )}
          {incomingGlobalCallList?.length > 0 && (
            <IncomingGlobalCard
              incomingGlobalCallList={incomingGlobalCallList}
              show={true}
            />
          )}
        </>
      )}
    </div>
  );
};

export default NewGlobalSearch;
