import { IconButton, CardHeader, Tooltip, Dialog } from "@mui/material";
import Iconify from "../../components/iconify";

export default function ImportData({ open, handleClose }) {
  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth={"md"}
    >
      <CardHeader
        action={
          <Tooltip title="Close">
            <IconButton
              color="error"
              className="CloseBtn"
              onClick={() => {
                handleClose();
              }}
            >
              <Iconify icon="mdi:cancel-circle-outline" />
            </IconButton>
          </Tooltip>
        }
        title={"Globa Search "}
        sx={{ textAlign: "center" }}
      />
    </Dialog>
  );
}
