import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  Button,
  CardContent,
  Typography,
  CardHeader,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "src/auth/useAuthContext";
import { AnalyticsWidgetSummary } from "src/sections/@dashboard/general/analytics";
import { staffAssignTaskReportData } from "src/service";

const timingOption = [
  { label: "All", value: "all" },
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Before Yesterday", value: "beforeYesterday" },
  { label: "Last 3 Days", value: "lastThreeDays" },
  { label: "Last Week", value: "lastWeek" },
  { label: "Last Month", value: "lastMonth" },
  { label: "Last Year", value: "lastYear" },
];

export default function ScannerBioDataUsers() {
  const contxt = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { isJainActive } =  location.state || {}; 
  const [uploadCount, setUploadCount] = useState({});
  const [filter, setFilter] = useState({
    role: contxt?.user?.sections?.ScannerData?.WorkFromHomeStaff
      ? "WorkFromHomeStaff"
      : contxt?.user?.sections?.ScannerData?.isAdmin
      ? "admin"
      : "staff",
    user_id: contxt?.user?.id,
  });

  const fetchStaffAssignTaskReport = async () => {
    try {
      const obj = {
        staff_id: contxt?.user?.id,
        timing: filter?.staff_filter_timing,
        source_table: isJainActive == true ? "jain_data" : "hindu_data",
        report_type: "upload",
      };
      const response = await staffAssignTaskReportData(obj);
      // console.log(response);
      if (response?.status === 200) {
        setUploadCount(response?.data?.data[0] || {});
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Scanner Bio Upload Report : Master Admin</title>
      </Helmet>
      <Card>
        <CardHeader
          title={<Typography variant="h4">{contxt?.user?.nameEnglish} Performance Report</Typography>}
          action={
            <Button
              variant="contained"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </Button>
          }
        />
        <CardContent sx={{ pb: 3 }}>
          <Grid container spacing={2}>
            <>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Timing</InputLabel>
                  <Select
                    value={filter?.staff_filter_timing || ""}
                    onChange={(e) =>
                      setFilter({
                        ...filter,
                        staff_filter_timing: e.target.value,
                      })
                    }
                  >
                    {timingOption.map((item) => (
                      <MenuItem key={item?.value} value={item?.value}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={fetchStaffAssignTaskReport}
                >
                  Apply Filter
                </Button>
              </Grid>
            </>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsWidgetSummary
                title="Total Upload Data"
                total={uploadCount?.upload_data}
                color="secondary"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsWidgetSummary
                title="Total Failed To Upload Data"
                total={uploadCount?.failed_to_upload}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsWidgetSummary
                title="Total Duplicate Data"
                total={uploadCount?.duplicate_data}
                color="warning"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <AnalyticsWidgetSummary
                title="Total Unique Data"
                total={uploadCount?.unique_data}
                color="success"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
